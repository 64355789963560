import React, { useState, useEffect, useContext } from "react";
import { RegContext } from "../../regContext/RegContext";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Stack from "@mui/material/Stack";
import { createTheme, ThemeProvider, Theme } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatDate } from "../../../../utils/dateUtils";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}
declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
  }
}

interface Partenaire {
  _id: string;
  nomPartenaire: string;
  activitePartenaire: string;
  emailPartenaire1: string;
  telPartenaire: string;
  communePartenaire: string;
  valide: boolean;
  desactive?: boolean;
  visionnages: any[];
  dateCreation: string;
  fixePartenaire?: string;
  descriptionPartenaire?: string;
  villePartenaire?: string;
}
interface DetailsProps {
  valide: boolean;
  desactive: boolean;
  partId: string;
  partNom: string;
  partEmail: string;
  partEmail2: number;
  partActivite: string;
  partTel: string;
  partFixe: string;
  partDescription: string;
  partVille: string;
  partCommune: string;
  dateCreation: string;
  onClose: () => void;
  deletecategory: (id: string) => void;
  fetchCategories: () => void;
}

/* const { BACKEND_API_URL } = require("../../../../env");
const BACKEND_TEST_URL = "https://giga-plus-dev-ec172b1d0071.herokuapp.com"; */
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface BilanProps {
  count: number | string;
  title: string;
}

const Bilan: React.FC<BilanProps> = ({ count, title }) => {
  return (
    <div className="sect1_bilan">
      <div>{count}</div>
      <span>{title}</span>
    </div>
  );
};

const theme: Theme = createTheme({
  palette: {
    neutral: {
      main: "#4EAC04",
      contrastText: "#fff",
    },
  },
});

const theme2: Theme = createTheme({
  palette: {
    neutral: {
      main: "#901d09",
      contrastText: "#fff",
    },
  },
});

const Details: React.FC<DetailsProps> = (props) => {
  const { checkStatus, setCheckStatus } = useContext(RegContext);
  const [errorText, setErrorText] = useState("");
  const [partSuccess, setPartSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [isValide, setIsValide] = useState(props.valide);
  const [partErrorText, setPartErrorText] = useState("");
  const [partError, setPartError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDeactivated, setIsDeactivated] = useState(props.desactive);

  const handleSuccess = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setPartSuccess(false);
  };

  const handleError = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setPartError(false);
  };

  const handleDesactiveSuccess = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setDesactive(false);
  };

  const handleDesactiveError = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setDesactiveError(false);
  };

  const handleReactiveSuccess = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setActif(false);
  };

  const handleReactiveError = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setActifError(false);
  };

  const handleValidation = async () => {
    setLoading(true);

    Promise.all([
      axios.patch(`${apiUrl}/api/partenaireValidation/${props.partId}`),
      axios.post(`${apiUrl}/api/sendMailValidation`, {
        emailPartenaire1: props.partEmail,
      }),
    ])
      .then(([validationPartenaire, sendMailValidation]) => {
        console.log(validationPartenaire.data);
        console.log(sendMailValidation.data);
        setPartSuccess(true);
        setSuccessText(sendMailValidation.data.msg);
        setIsValide(true);
        setLoading(false);
        setCheckStatus(!checkStatus);
      })
      .catch((error) => {
        console.log(error);
        setPartError(true);
        setPartErrorText(error.response.data.message);
        setLoading(false);
      });
  };

  const [desactive, setDesactive] = useState(false);
  const [desactiveError, setDesactiveError] = useState(false);
  const [desactiveText, setDesactiveText] = useState("");
  const [desactiveErrorText, setDesactiveErrorText] = useState("");
  const [actif, setActif] = useState(false);
  const [actifError, setActifError] = useState(false);
  const [actifErrorText, setActifErrorText] = useState("");
  const [actifText, setActifText] = useState("");

  const handleDesactiver = async () => {
    setLoading(true);

    try {
      const res = await axios.patch(
        `${apiUrl}/api/partenaireDesactivation/${props.partId}`
      );

      console.log(res.data);
      setDesactive(true);
      setDesactiveText(res.data.message);
      setIsDeactivated(true);
      setLoading(false);
      setCheckStatus(!checkStatus);
    } catch (error: any) {
      console.log(error);
      setDesactiveError(true);
      setDesactiveErrorText(error.response.data.message);
      setLoading(false);
    }
  };

  const handleReactiver = async () => {
    setLoading(true);

    try {
      const res = await axios.patch(
        `${apiUrl}/api/partenaireReactivation/${props.partId}`
      );
      setActif(true);
      setActifText(res.data.message);
      setIsDeactivated(false);
      setLoading(false);
      setCheckStatus(!checkStatus);
    } catch (error: any) {
      setActifError(true);
      setActifErrorText(error.response.data.message);
      setLoading(false);
    }
  };

  return (
    <div className="sect3_elem_details">
      <div>
        <div onClick={props.onClose} className="elem_details_closure">
          {" "}
          <i className="fa-solid fa-xmark"></i>{" "}
        </div>

        <div className="part_details_div">
          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                {" "}
                <b> {props.partNom} </b>{" "}
              </span>
              <span> {props.partDescription} </span>
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> Partenaire : &nbsp; </span>{" "}
                <span> {props.partNom} </span>{" "}
              </div>
              <div>
                <span> Email 1 : &nbsp; </span> <span> {props.partEmail} </span>{" "}
              </div>
              <div>
                <span> Vues : &nbsp; </span> <span> {props.partEmail2} </span>{" "}
              </div>
              <div>
                <span> Activite : &nbsp; </span>{" "}
                <span> {props.partActivite} </span>{" "}
              </div>
              <div>
                <span> Télephone : &nbsp; </span> <span> {props.partTel} </span>{" "}
              </div>
              <div>
                <span> date d'inscription : &nbsp; </span>{" "}
                <span> {formatDate(props.dateCreation)}</span>{" "}
              </div>
            </div>
          </div>

          <div>
            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={partError}
                autoHideDuration={3000}
                onClose={handleError}
              >
                <Alert
                  onClose={(event: React.SyntheticEvent<Element, Event>) =>
                    handleError(event, "")
                  }
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {partErrorText}
                </Alert>
              </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={partSuccess}
                autoHideDuration={3000}
                onClose={handleSuccess}
              >
                <Alert
                  onClose={(event: React.SyntheticEvent<Element, Event>) =>
                    handleSuccess(event, "")
                  }
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  {successText}
                </Alert>
              </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={desactive}
                autoHideDuration={3000}
                onClose={handleDesactiveSuccess}
              >
                <Alert
                  onClose={(event: React.SyntheticEvent<Element, Event>) =>
                    handleDesactiveSuccess(event, "")
                  }
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  {desactiveText}
                </Alert>
              </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={desactiveError}
                autoHideDuration={3000}
                onClose={handleDesactiveError}
              >
                <Alert
                  onClose={(event: React.SyntheticEvent<Element, Event>) =>
                    handleDesactiveError(event, "")
                  }
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {desactiveErrorText}
                </Alert>
              </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={actif}
                autoHideDuration={3000}
                onClose={handleReactiveSuccess}
              >
                <Alert
                  onClose={(event: React.SyntheticEvent<Element, Event>) =>
                    handleReactiveSuccess(event, "")
                  }
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  {actifText}
                </Alert>
              </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={actifError}
                autoHideDuration={3000}
                onClose={handleReactiveError}
              >
                <Alert
                  onClose={(event: React.SyntheticEvent<Element, Event>) =>
                    handleReactiveError(event, "")
                  }
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {actifErrorText}
                </Alert>
              </Snackbar>
            </Stack>

            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                {" "}
                <b> {props.partNom} </b>{" "}
              </span>
              <span> {props.partDescription} </span>
            </div>

            <div className="row1_details_details">
              <br />
              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> Ville : &nbsp; </span> <span> {props.partVille} </span>{" "}
              </div>
              <div>
                <span> Commune : &nbsp; </span>{" "}
                <span> {props.partCommune} </span>{" "}
              </div>
              <div>
                <span> Description Activite : &nbsp; </span>{" "}
                <span> {props.partDescription} </span>{" "}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "20vh",
                  backgroundColor: "#f6f6f6",
                  borderRadius: "0.4rem",
                  position: "relative",
                }}
                className="row_status_div"
              >
                <div>
                  <span> Status inscription : &nbsp; </span>{" "}
                  <span
                    className={
                      isValide
                        ? "row_status_div_valide"
                        : "row_status_div_nonvalide"
                    }
                  >
                    {" "}
                    {isValide ? "Validé" : "Non validé"}{" "}
                  </span>{" "}
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: "13vh",
                    height: "4vh",
                    backgroundColor: "#f6f6f6",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!isValide && (
                    <ThemeProvider theme={theme}>
                      <Button
                        onClick={handleValidation}
                        color="neutral"
                        variant="contained"
                        endIcon={<CheckCircleIcon />}
                      >
                        Valider Inscription
                      </Button>
                    </ThemeProvider>
                  )}

                  {isValide && isDeactivated && (
                    <ThemeProvider theme={theme}>
                      <Button
                        onClick={handleReactiver}
                        color="neutral"
                        variant="contained"
                        endIcon={<CheckCircleIcon />}
                      >
                        Réactiver
                      </Button>
                    </ThemeProvider>
                  )}

                  {!isDeactivated && isValide && (
                    <ThemeProvider theme={theme2}>
                      <Button
                        onClick={handleDesactiver}
                        color="neutral"
                        variant="contained"
                        endIcon={<CheckCircleIcon />}
                      >
                        Désactiver
                      </Button>
                    </ThemeProvider>
                  )}
                </div>
              </div>

              <div>
                <b> Status activité : &nbsp; </b>{" "}
                <b className={isDeactivated ? "partStatus2" : "partStatus1"}>
                  {" "}
                  {isDeactivated ? "désactivé" : "actif"}
                </b>{" "}
              </div>
            </div>

            {loading && (
              <ScaleLoader
                style={{
                  position: "absolute",
                  top: "12vh",
                  left: "48%",
                }}
                color="#4EAC04"
              />
            )}
          </div>
        </div>
        <div className="mt-16 text-right">
          <button
            type="button"
            onClick={() => {
              props.deletecategory(props.partId);
              props.onClose();
            }}
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-200"
          >
            Supprimer le parttenaire
          </button>
        </div>
      </div>
    </div>
  );
};

const Sect1 = () => {
  const { checkStatus, setCheckStatus } = useContext(RegContext);

  const [displayItem, setDisplayItem] = useState(false);
  const { checkValid, setCheckValid, setTotalPartenaires, totalPartenaires } =
    useContext(RegContext);
  const [loading, setLoading] = useState(false);
  const { prospects, setProspects } = useContext(RegContext);
  const [partenaires, setPartenaires] = useState<Partenaire[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [totalItems, setTotalItems] = useState(0);
  const [currPartenaire, setCurrPartenaire] = useState<Partenaire | null>(null);

  const closeChild = () => {
    setDisplayItem(false);
  };

  const fetchCategories = () => {
    setLoading(true);
    const limit = 8;
    const pageNumber = currentPage;
    axios
      .get(
        `${apiUrl}/api/partenaires?limit=${limit}&page=${pageNumber}&search=${searchTerm}`
      )
      .then((response) => {
        const filteredPartenaires = response.data.partenaires.filter(
          (partenaire: { valide: any }) => partenaire.valide === checkValid
        );
        setPartenaires(response.data.partenaires);
        setTotalItems(response.data.count);

        setTotalPartenaires(response.data.count);

        const filteredProspects = response.data.partenaires.filter(
          (partenaire: { valide: boolean }) => partenaire.valide === false
        );

        setProspects(response.data.prospects);

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    const limit = 8;
    const pageNumber = currentPage;
    axios
      .get(
        `${apiUrl}/api/partenaires?limit=${limit}&page=${pageNumber}&search=${searchTerm}`
      )
      .then((response) => {
        console.log("les donnees partenaires : ", response.data);

        const filteredPartenaires = response.data.partenaires.filter(
          (partenaire: { valide: any }) => partenaire.valide === checkValid
        );
        setPartenaires(response.data.partenaires);
        setTotalItems(response.data.count);

        setTotalPartenaires(response.data.count);

        const filteredProspects = response.data.partenaires.filter(
          (partenaire: { valide: boolean }) => partenaire.valide === false
        );

        setProspects(response.data.prospects);

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [checkValid, checkStatus]);

  const setValidP = () => {
    setCheckValid(true);
  };

  const setInvalidP = () => {
    setCheckValid(false);
  };

  const handlePartenaire = (partenaire: Partenaire) => {
    console.log(partenaire);
    setCurrPartenaire(partenaire);
    setDisplayItem(true);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = partenaires
    .filter(
      (partenaire) =>
        partenaire.nomPartenaire
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        partenaire.activitePartenaire
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        partenaire.emailPartenaire1
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        partenaire.telPartenaire
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        partenaire.communePartenaire
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    )
    .slice(indexOfFirstItem, indexOfLastItem);

  const [nombrePartenaire, setNombrePartenaire] = useState(0);
  const [nombreVideo, setNombreVideo] = useState(0);
  const [nombreVisionnage, setNombreVisionnage] = useState(0);

  useEffect(() => {
    const limit = 8;
    const pageNumber = currentPage;
    Promise.all([
      axios.get(
        `${apiUrl}/api/partenaires?limit=${limit}&page=${pageNumber}&search=${searchTerm}`
      ),
      axios.get(`${apiUrl}/api/video/getVideos`),
      axios.get(`${apiUrl}/api/visionnage/getTotal`),
    ])
      .then(([dataPartenaire, dataVideos, dataVisionnage]) => {
        const partenaires = dataPartenaire.data.partenaires;
        const count = dataPartenaire.data.count;
        setNombrePartenaire(count);

        const videosData = dataVideos.data;
        const count2 = videosData.length;
        setNombreVideo(count2);
        const visionnages = dataVisionnage.data.total;
        setNombreVisionnage(visionnages);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [checkStatus]);

  const filterDeactivated = () => {
    axios.get(`${apiUrl}/api/partenaires`).then((response) => {
      const filteredPartenaires = response.data.filter(
        (partenaire: { desactive: boolean }) => partenaire.desactive === true
      );
      setPartenaires(filteredPartenaires);
    });
  };

  const filterActif = () => {
    axios.get(`${apiUrl}/api/partenaires`).then((response) => {
      const filteredPartenaires = response.data.filter(
        (partenaire: { desactive: boolean | undefined }) =>
          partenaire.desactive === undefined || partenaire.desactive === false
      );
      setPartenaires(filteredPartenaires);
    });
  };

  const paginate = async (pageNumber: React.SetStateAction<number>) => {
    setLoading(true);
    setCurrentPage(pageNumber);

    const limit = 8;

    try {
      const response = await axios.get(
        `${apiUrl}/api/partenaires?limit=${limit}&page=${pageNumber}&search=${searchTerm}`
      );
      setPartenaires(response.data.partenaires);

      console.log("current partenaores: " + partenaires);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const searchHandler2 = (e: any) => {
    setLoading(true);
    const limit = itemsPerPage;
    const page = currentPage;
    // const pageNumber = page;

    console.log("searchTerm2: " + searchTerm);
    Promise.all([
      axios.get(
        `${apiUrl}/api/partenaires?limit=${limit}&page=${page}&search=${searchTerm}`
      ),
    ])

      .then(([dataPartenaires]) => {
        // setHommesSandwich(dataHommeSandwich.data.allAddPartenaire);
        setPartenaires(dataPartenaires.data.partenaires);

        // console.log("searched: " + dataHommeSandwich.data.allAddPartenaire);
        // setTotalPages2(dataHommeSandwich.data.totalPages);
        setLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteCategory = async (id: any) => {
    console.log("Deleting category with id:", id);
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce partenaire?")) {
      try {
        const response = await axios.delete(
          `${apiUrl}/api/partenaires/deletedpartenaires/${id}`
        );
        console.log("Category deleted:", response.data);
        fetchCategories();
        toast.success("Catégorie supprimée avec succès.");
      } catch (error) {
        toast.error("Erreur lors de la suppression de la catégorie");
      }
    }
  };

  return (
    <div className="partenaires_sect1">
      <div className="partenaires_sect1_top">
        <Bilan title="Partenaires inscrits" count={nombrePartenaire} />
      </div>

      <div className="partenaires_sect1_bottom">
        <div className="partenaires_sect1_bottom_sect_top">
          <div className="partStatusFilter">
            <button
              onClick={(e) => {
                filterDeactivated();
              }}
              style={{ backgroundColor: "#ef5a2b", color: "white" }}
            >
              Desactivés
            </button>
            <button
              onClick={(e) => {
                filterActif();
              }}
              style={{ backgroundColor: "#4EAC04", color: "white" }}
            >
              Actifs
            </button>
          </div>

          <div>Liste partenaires</div>
          <div>
            <div className=".search-bar-container2">
              <input
                type="text"
                placeholder="Rechercher..."
                className="search-bar-hs3"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button onClick={searchHandler2} className="search-button3">
                <i className="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>

        <div className="partenaires_sect1_bottom_sect_table">
          <table>
            <thead>
              <tr>
                <th>##</th>
                <th>Nom</th>
                <th>Vues</th>
                <th>Activite</th>
                <th>Telephone</th>
                <th>Status</th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <ScaleLoader
                  style={{
                    position: "absolute",
                    top: "12vh",
                    left: "48%",
                  }}
                  color="#4EAC04"
                />
              )}

              {partenaires.map((partenaire: Partenaire, index) => (
                <tr key={partenaire._id}>
                  <td>{partenaires.length - index}</td>
                  <td> {partenaire.nomPartenaire} </td>
                  <td>{partenaire.visionnages.length} </td>
                  <td>{partenaire.activitePartenaire}</td>
                  <td>{partenaire.telPartenaire}</td>
                  <td>
                    {" "}
                    <b
                      className={
                        partenaire.desactive !== undefined &&
                        partenaire.desactive === true
                          ? "partStatus2"
                          : "partStatus1"
                      }
                    >
                      {" "}
                      {partenaire.desactive !== undefined &&
                      partenaire.desactive === true
                        ? "desactive"
                        : "actif"}{" "}
                    </b>{" "}
                  </td>

                  <td>
                    {" "}
                    <i
                      onClick={() => handlePartenaire(partenaire)}
                      className="fa-solid fa-tv"
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pagination">
            <button
              className="pagination-button"
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <i className="fa fa-angle-left"></i>
            </button>
            <span className="current-page">
              {currentPage}/{Math.ceil(totalItems / itemsPerPage)}
            </span>
            <button
              className="pagination-button"
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
            >
              <i className="fa fa-angle-right"></i>
            </button>
          </div>

          {displayItem && currPartenaire && (
            <Details
              valide={currPartenaire.valide}
              desactive={currPartenaire.desactive || false}
              partId={currPartenaire._id}
              partNom={currPartenaire.nomPartenaire}
              partEmail={currPartenaire.emailPartenaire1}
              partEmail2={currPartenaire.visionnages.length}
              partActivite={currPartenaire.activitePartenaire}
              partTel={currPartenaire.telPartenaire}
              partFixe={currPartenaire.fixePartenaire || ""}
              partDescription={currPartenaire.descriptionPartenaire || ""}
              partVille={currPartenaire.villePartenaire || ""}
              partCommune={currPartenaire.communePartenaire}
              dateCreation={currPartenaire.dateCreation}
              onClose={closeChild}
              deletecategory={handleDeleteCategory}
              fetchCategories={fetchCategories}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Sect1;
