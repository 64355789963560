import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { RegContext } from "../../regContext/RegContext";
import bannerbg from "../../../../images/home_banner.png";
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
const Bilan = (props: { count: number | string; title: React.ReactNode }) => {
  return (
    <div className="sect1_bilan">
      <div>{props.count}</div>
      <span>{props.title}</span>
    </div>
  );
};

interface Video {
  id: string;
  playCount?: number;
}

interface UseTotalPlaycountResult {
  totalPlaycount: number;
  isLoading: boolean;
  error: Error | null;
}

const Sect1: React.FC = () => {
  const [nombrePartenaire, setNombrePartenaire] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();
  const { totalParrainages } = useContext(RegContext);
  const [totalUsagers, setTotalUsagers] = useState(0);
  const [totalPlaycount, setTotalPlaycount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);

    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/video/getVideos`);
        const videos = response.data.videos;

        if (!Array.isArray(videos)) {
          throw new Error("Invalid video data received");
        }

        const totalCount = videos.reduce(
          (sum: number, video: Video) => sum + (video.playCount || 0),
          0
        );

        setTotalPlaycount(totalCount);
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("An unknown error occurred")
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const limit = 8;
    const pageNumber = currentPage;
    Promise.all([
      axios.get(
        `${apiUrl}/api/partenaires?limit=${limit}&page=${pageNumber}&search=${searchTerm}`
      ),
      axios.get(`${apiUrl}/api/video/getVideos`),
      axios.get(`${apiUrl}/api/visionnage/getTotal`),
    ])
      .then(([dataPartenaire, dataVideos, dataVisionnage]) => {
        const partenaires = dataPartenaire.data.partenaires;
        const count = dataPartenaire.data.count;
        setNombrePartenaire(count);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios.get(`${apiUrl}/api/partenaires`).then((res) => {
      setNombrePartenaire(res.data.count || 0);
    });
    axios.get(`${apiUrl}/api/usager/count`).then((response) => {
      setTotalUsagers(response.data.countUsager || 0);
    });
  }, []);

  return (
    <div className="sect1">
      <div>
        <Bilan title="Total Visionnages" count={totalPlaycount} />
        <Bilan title="Partenaires inscrits" count={nombrePartenaire} />
        <Bilan
          title="Usagers"
          count={totalUsagers !== undefined ? totalUsagers : 0}
        />
        <Bilan title="Parrainages" count={totalParrainages} />
        <Bilan title="Partages" count={0} />
      </div>
      <div style={{ backgroundImage: `url(${bannerbg})` }}></div>
    </div>
  );
};

export default Sect1;
