import React, { useState } from "react";

interface ModernTextInputProps {
  label: string;
  id: string;
  type?: string;
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ModernTextInput = ({
  label,
  id,
  type = "text",
  placeholder = " ",
  value,
  onChange,
}: ModernTextInputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="relative mb-4">
      <input
        type={type}
        id={id}
        className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-500"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <label
        htmlFor={id}
        className={`absolute left-0 -top-3.5 text-gray-600 text-sm transition-all
          ${
            isFocused || value
              ? "-top-3.5 text-sm text-blue-500"
              : "top-2 text-base"
          }
          peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2
          peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-blue-500`}
      >
        {label}
      </label>
    </div>
  );
};

export default ModernTextInput;
