import React, { useState, useEffect } from "react";
import ModernTextInput from "./ModernTextInput";
/* import { BACKEND_API_URL } from "../env";
const BACKEND_TEST_URL = "https://giga-plus-dev-ec172b1d0071.herokuapp.com"; */
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
const LoginBonusAdmin = () => {
  const [loginBonus, setLoginBonus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    console.log("Component mounted. Fetching current login bonus...");
    fetchCurrentLoginBonus();
  }, []);

  const fetchCurrentLoginBonus = async () => {
    console.log(
      "Fetching current login bonus from:",
      `${apiUrl}/api/login-bonus`
    );
    try {
      const response = await fetch(`${apiUrl}/api/login-bonus`);
      console.log("Fetch response:", response);
      const data = await response.json();
      console.log("Fetched data:", data);
      setLoginBonus(data.loginBonus);
      console.log("Login bonus set to:", data.loginBonus);
    } catch (error: any) {
      console.error("Error fetching login bonus:", error);
      setMessage("Échec de la récupération du bonus de connexion actuel");
      setMessageType("error");
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form submitted. Updating login bonus to:", loginBonus);
    setIsLoading(true);
    setMessage("");

    try {
      console.log(
        "Sending POST request to:",
        `${apiUrl}/api/login-bonus`
      );

      const response = await fetch(`${apiUrl}/api/login-bonus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ loginBonus: Number(loginBonus) }),
      });

      if (response.ok) {
        setMessage("Mise à jour avec succès");
        setMessageType("success");
      } else {
        setMessage("Échec de la mise à jour ");
        setMessageType("error");
      }
    } catch (error) {
      console.error("Error updating login bonus:", error);
      setMessage("Échec de la mise à jour");
      setMessageType("error");
    } finally {
      setIsLoading(false);
    }
  };

  console.log("Rendering component. Current state:", {
    loginBonus,
    isLoading,
    message,
    messageType,
  });

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
      <h2 className="text-2xl font-bold mb-6">Bonus création de compte</h2>
      <form onSubmit={handleSubmit}>
        <ModernTextInput
          label="Somme bonus création de compte"
          id="loginBonus"
          type="number"
          value={loginBonus}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setLoginBonus(e.target.value)
          }
        />
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
          disabled={isLoading}
        >
          {isLoading ? "Mise à jour..." : "Mettre à jour"}
        </button>
      </form>
      {message && (
        <div
          className={`mt-4 p-3 rounded ${
            messageType === "success"
              ? "bg-green-100 text-green-400"
              : "bg-red-100 text-red-400"
          }`}
        >
          {message}
        </div>
      )}
    </div>
  );
};

export default LoginBonusAdmin;
