import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
/* const { BACKEND_API_URL } = require("../../env"); */
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Settings = () => {
  const [cookies] = useCookies(["giga_admin"]);
  const [nomAdmin, setNomAdmin] = useState(cookies.giga_admin.nomAdmin);
  const [emailAdmin, setEmailAdmin] = useState(cookies.giga_admin.emailAdmin);
  const [telAdmin, setTelAdmin] = useState(cookies.giga_admin.telAdmin);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successText, setSuccessText] = useState("Modifications enregistrées");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("Erreur lors de la modification");
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");

  const handleSuccess = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  const handleError = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const handleUpdate1 = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();

    setLoading(true);

    axios
      .patch(`${apiUrl}/api/adminUpdate/${cookies.giga_admin.idAdmin}`, {
        nomAdmin: nomAdmin,
        emailAdmin: emailAdmin,
        telAdmin: telAdmin,
      })
      .then((response) => {
        console.log(response.data.data);
        setLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setError(true);
      });
  };

  const handleUpdate2 = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();

    setLoading(true);

    axios
      .patch(
        `http://localhost:3005/api/adminUpdatePassword/${cookies.giga_admin.idAdmin}`,
        {
          password: pass1,
          pass2: pass2,
        }
      )
      .then((response) => {
        console.log(response.data.data);
        setLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setErrorText(error.response.data.message);
        setError(true);
      });
  };

  return (
    <div className="settings_page">
      <div>
        <div>
          <h4>Paramètres de compte</h4>

          {loading && (
            <ScaleLoader
              style={{
                position: "relative",
              }}
              color="#4EAC04"
            />
          )}
        </div>
        <div className="settings_user_details_top">
          <div></div>
          <div>
            {" "}
            <h4>
              Admin <b>{cookies.giga_admin.nomAdmin}</b>{" "}
            </h4>{" "}
          </div>
        </div>
      </div>

      <div className="settings_user_details_bottom">
        <div>
          <h6>Informations de compte</h6>
          <span style={{ fontSize: "0.8rem" }}>
            {" "}
            -&nbsp; Mettez à jour votre profil ici. -&nbsp;
          </span>
        </div>

        <div className="settings_page_infos_box">
          <label htmlFor="nom">Nom</label>
          <input
            onChange={(e) => setNomAdmin(e.target.value)}
            id="nom"
            type="text"
            placeholder="Entrer votre email"
            name=""
            defaultValue={cookies.giga_admin.nomAdmin}
          />
        </div>

        <div className="settings_page_infos_box">
          <label htmlFor="email">Email</label>
          <input
            onChange={(e) => setEmailAdmin(e.target.value)}
            id="email"
            type="text"
            placeholder="Entrer votre email"
            name=""
            defaultValue={cookies.giga_admin.emailAdmin}
          />
        </div>

        <div className="settings_page_infos_box">
          <label htmlFor="tel">Numéro</label>
          <input
            onChange={(e) => setTelAdmin(e.target.value)}
            id="tel"
            type="text"
            placeholder="Entrer votre email"
            name=""
            defaultValue={cookies.giga_admin.telAdmin}
          />
          <span
            onClick={handleUpdate1}
            style={{ right: "45vw" }}
            className="settings_up_button"
          >
            Mettre à jour
          </span>
        </div>

        <div>
          <div className="settings_page_infos_box">
            <label htmlFor="omdp">Ancien mot de pass</label>
            <input
              onChange={(e) => setPass1(e.target.value)}
              style={{ left: "15vw" }}
              id="omdp"
              type="password"
              placeholder="Ancien mot de pass"
              name=""
            />
          </div>
          <div className="settings_page_infos_box">
            <label htmlFor="nmdp">Nouveau mot de pass</label>
            <input
              onChange={(e) => setPass2(e.target.value)}
              style={{ left: "15vw" }}
              id="nmdp"
              type="password"
              placeholder="Nouveau mot de pass"
              name=""
            />
            <span onClick={handleUpdate2} className="settings_up_button">
              Mettre à jour
            </span>
          </div>
        </div>

        <Stack spacing={2} sx={{ width: "70%" }}>
          <Snackbar open={error} autoHideDuration={3000} onClose={handleError}>
            <Alert
              onClose={handleError}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorText}
            </Alert>
          </Snackbar>
        </Stack>

        <Stack spacing={2} sx={{ width: "70%" }}>
          <Snackbar
            open={success}
            autoHideDuration={3000}
            onClose={handleSuccess}
          >
            <Alert
              onClose={handleSuccess}
              severity="success"
              sx={{ width: "100%" }}
            >
              {successText}
            </Alert>
          </Snackbar>
        </Stack>
      </div>
    </div>
  );
};

export default Settings;
