import React, { useState, useEffect, useContext } from "react";
import banner_bg from "../components/pages/images/home_banner.png";
import axios from "axios";
import { RegContext } from "../components/pages/regContext/RegContext";
/* const BACKEND_TEST_URL = "https://giga-plus-dev-ec172b1d0071.herokuapp.com";
const { BACKEND_API_URL } = require("../env"); */

const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

const Bilan = (props: any) => {
  return (
    <div className="sect1_bilan">
      <div>{props.count}</div>
      <span>{props.title}</span>
    </div>
  );
};

const GestionHeader = () => {
  const [nombrePartenaire, setNombrePartenaire] = useState(0);
  const [nomrebHommeSandwich, setNombreHommeSandwich] = useState(0);
  const [nombreVisionnage, setNombreVisionnage] = useState(0);
  const [nombreUsagers, setNombreUsagers] = useState(0);

  const [visionnagesParM, setVisionnagesParM] = useState(0);
  const [visionnagesParF, setVisionnagesParF] = useState(0);
  const [stats, setStats] = useState({
    totalCategories: 0,
    totalBuckets: 0,
    totalProducts: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/categories/stats`);
        console.log(" donne total", response.data);
        setStats(response.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  const {
    setTotalPartenaires,
    totalPartenaires,
    totalUsagers,
    setTotalUsagers,
    totalParrainages,
    setTotalParrainages,
  } = React.useContext(RegContext);

  useEffect(() => {
    Promise.all([
      axios.get(`${apiUrl}/api/getCountPartenaire`),
      axios.get(`${apiUrl}/api/visionnage/getTotal`),
      axios.get(`${apiUrl}/api/usager/getCountUsager`),
      axios.get(`${apiUrl}/api/parrainage/number`),
    ])
      .then(
        ([
          dataPartenaire,
          dataVisionnage,
          dataCountUsager,
          dataCountParrainage,
        ]) => {
          const partenaires = dataPartenaire.data.count;
          const count = partenaires.length;
          setNombrePartenaire(partenaires);

          setTotalPartenaires(partenaires);

          const visionnages = dataVisionnage.data.total;

          setNombreVisionnage(visionnages);

          const countUsager = dataCountUsager.data;
          setNombreUsagers(countUsager.countUsager);

          setTotalUsagers(countUsager.countUsager);

          setVisionnagesParM(countUsager.countMal);
          setVisionnagesParF(countUsager.countFemelle);
          setTotalParrainages(dataCountParrainage.data);
        }
      )
      .catch((error) => {});
  }, []);

  return (
    <div className="sect1">
      <div>
        <Bilan title="catégories totales" count={stats.totalCategories} />
        <Bilan title="Paniers totales" count={stats.totalBuckets} />
        <Bilan title="Produits totales" count={stats.totalProducts} />
      </div>
      <div style={{ backgroundImage: `url(${banner_bg})` }}></div>
    </div>
  );
};

export default GestionHeader;
