import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RegContext } from "../../regContext/RegContext";
import { ScaleLoader } from "react-spinners";

/* const { BACKEND_API_URL } = require("../../../../env");
const BACKEND_TEST_URL = "https://giga-plus-dev-ec172b1d0071.herokuapp.com"; */
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
interface User {
  _id: string;
  email: string;
  pointRetrait: number;
  numPaiement: string;
  moyenPaiement: string;
  dateAjout: string;
  datePaiement: string | null;
  refPaiment: string;
}
interface Retrait {
  _id: string;
  datePaiement: string | null;
  email: string;
  pointRetrait: number;
  numPaiement: string;
  moyenPaiement: string;
}
interface DetailsProps {
  id: string;
  email: string;
  pointRetrait: number;
  numPaiement: string;
  moyenPaiement: string;
  dateDemande: string;
  datePaiement: string | null;
  refPaiment: string;
  onClose: () => void;
}

const Details = (props: DetailsProps) => {
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setRetraitError(false);
  };

  const { retraitStatus, setRetraitStatus, retraitflag1, setRetraitflag1 } =
    useContext(RegContext);
  const [refTerm, setRefTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [retraitError, setRetraitError] = useState(false);

  const handleUpdateRetrait = async () => {
    if (!refTerm) {
      toast.error("Vous devez ajouter une référence de paiement");
      return;
    }
    setLoading(true);

    try {
      const response = await axios.put(
        `${apiUrl}/api/retrait/updateRetrait/${props.id}`,
        {
          refPaiment: refTerm,
          email: props.email,
        }
      );
      toast.success("Retrait confirmé avec succès", {
        onClose: () => {
          window.location.reload();
        },
      });
    } catch (error: any) {
      toast.error("Erreur lors de la mise à jour du retrait");
    } finally {
      setLoading(false);
    }
  };

  const date = new Date(props.dateDemande);
  const date1 = props.datePaiement ? new Date(props.datePaiement) : null;
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  } as const;
  const formattedDate1 = date ? date.toLocaleString("fr-FR", options) : "";
  const formattedDate2 = date1 ? date1.toLocaleString("fr-FR", options) : "";
  return (
    <div className="sect3_elem_details">
      <div>
        <div onClick={props.onClose} className="elem_details_closure">
          {" "}
          <i className="fa-solid fa-xmark"></i>{" "}
          <Stack spacing={2} sx={{ width: "70%" }}>
            <Snackbar
              open={retraitError}
              autoHideDuration={2000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                Retrait confirmé avec succès, un email de confirmation a été
                envoyé à l'utilisateur
              </Alert>
            </Snackbar>
          </Stack>
        </div>

        <div className="part_details_div">
          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}> </span>
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> ID : &nbsp; </span> <span> {props.id} </span>{" "}
              </div>
              <div>
                <span> Nom Utilisateur : &nbsp; </span>{" "}
                <span>
                  {props.email} &nbsp; <b>S</b>{" "}
                </span>
              </div>
              <div>
                <span> Numero Telephone : &nbsp; </span>{" "}
                <span> {props.numPaiement} </span>{" "}
              </div>
              <div>
                <span> Sommes dus: &nbsp; </span>{" "}
                <span
                  style={{
                    backgroundColor: "#ac180431",
                    color: "#ac1804",
                  }}
                >
                  {" "}
                  {props.pointRetrait} FCFA{" "}
                </span>{" "}
              </div>
              <div>
                <span> Moyen de paiment : &nbsp; </span>{" "}
                <span> {props.moyenPaiement} </span>{" "}
              </div>
            </div>
          </div>

          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> </b>{" "}
              </span>
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> Date demande : &nbsp; </span>{" "}
                <span>{formattedDate1} &nbsp;</span>
              </div>

              {props.datePaiement === null ? (
                <>
                  {retraitflag1 == false ? (
                    <>
                      <div
                        style={{
                          backgroundColor: "gold",
                        }}
                      >
                        <span>
                          <b>Cette demande vient d'être traitée </b>
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <input
                          type="text"
                          placeholder="Entrer la reference de paiement"
                          className="search-bar-hs2"
                          style={{
                            width: "29vw",
                          }}
                          value={refTerm}
                          onChange={(e) => setRefTerm(e.target.value)}
                        />
                      </div>
                      <div>
                        <button
                          style={{
                            position: "relative",
                            left: "1px",
                            fontSize: "12px",
                            color: "#4eac04",
                          }}
                          onClick={handleUpdateRetrait}
                          disabled={loading}
                          className="search-button2"
                        >
                          {loading ? "Chargement..." : "Confirmer"}
                        </button>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div>
                    <span
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      Date de confirmation : &nbsp;{" "}
                    </span>{" "}
                    <span>{formattedDate2}</span>
                  </div>

                  <div>
                    <span
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      Reference {props.moyenPaiement}: &nbsp;{" "}
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      {props.refPaiment}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};
const Bilan = (props: {
  color: any;
  count:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
  title:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
}) => {
  return (
    <div className="sect1_bilan">
      <div style={{ borderColor: props.color }}>{props.count}</div>
      <span>{props.title}</span>
    </div>
  );
};
const Sect1 = () => {
  const [nomOperateur, setNomOperateur] = useState([]);
  const [info, setInfo] = useState([]);
  const [displayItem, setDisplayItem] = useState(false);
  const {
    retraitStatus,
    setRetraitStatus,
    setTotalPartenaires,
    totalPartenaires,
    totalUsagers,
    setTotalUsagers,
    totalParrainages,
    setTotalParrainages,
    retraitflag1,
    setRetraitflag1,
  } = useContext(RegContext);

  const [total, setTotal] = useState(0);
  const [itemsPerPage2, setItemsPerPage2] = useState(8);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [currentPage2, setCurrentPage2] = useState(1);
  const [totalPages2, setTotalPages2] = useState(0);
  const [hommesSandwich, setHommesSandwich] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [visionnages, setVisionnages] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [countDemandes, setCountDemandes] = useState(0);
  const [retaitStatus, setRetaitStatus] = useState(false);
  const [retraits, setRetraits] = useState<Retrait[]>([]);
  const [user, setUser] = useState<User | null>(null);

  const closeChild = () => {
    setDisplayItem(false);
    setRetraitflag1(false);
  };
  useEffect(() => {
    setLoading(true);
    const limit1 = itemsPerPage2;
    const page = currentPage2;
    const statusParam = retaitStatus ? "true" : "false";

    axios
      .get(
        `${apiUrl}/api/retrait?limit=${limit1}&page=${page}&search=${searchTerm2}&status=${statusParam}`
      )
      .then((response) => {
        console.log("response retrait:", response.data);
        setRetraits(response.data.retraits);
        setCountDemandes(response.data.countFalse);
        setTotalPages2(response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage2, searchTerm2, retaitStatus, retraitStatus]);

  const paginate2 = (pageNumber: React.SetStateAction<number>) => {
    setCurrentPage2(pageNumber);
    setLoading(true);
    const limit1 = itemsPerPage2;
    const page = pageNumber;
    const statusParam = retaitStatus ? "true" : "false";

    axios
      .get(
        `${apiUrl}/api/retrait?limit=${limit1}&page=${page}&status=${statusParam}`
      )
      .then((response) => {
        setRetraits(response.data.retraits);
        setCountDemandes(response.data.countFalse);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRefresh = () => {
    setRetaitStatus(false);
  };

  const handleHistory = () => {
    setRetaitStatus(true);
  };

  const handleUser = (info: User) => {
    setUser(info);
    setDisplayItem(true);
  };

  return (
    <div className="partenaires_sect1 points_sect1">
      <div className="partenaires_sect1_top">
        <div
          className="donnees_sect1_bilan_left"
          style={{
            fontSize: "12px",
          }}
        >
          {" "}
          Gestion points & parrainages{" "}
        </div>

        <Bilan title="Retraits en attente" count={countDemandes} color="" />
        <Bilan title="Parrainages" count={totalParrainages} color="" />
        <Bilan title="Partenaires" count={17} color="" />
        <Bilan title="Usagers" count={totalUsagers} color="" />
        <Bilan title="Totales partages" count={0} color="" />
      </div>

      <div className="partenaires_sect1_bottom">
        <div className="partenaires_sect1_bottom_sect_topPoints partenaires_sect1_bottom_sect_topPoints3">
          <span className="blink">({countDemandes}) nouvelles</span>
          <div>Demandes de retrait</div>
          <div className="search-bar-container2">
            <input
              type="text"
              placeholder="Recherche par email"
              className="search-bar-hs2"
              value={searchTerm2}
              onChange={(e) => setSearchTerm2(e.target.value)}
            />
            <button
              disabled={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? true
                  : false
              }
              className={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? "search-button-disabled2"
                  : "search-button2"
              }
            >
              <i className="fa fa-search"></i>
            </button>

            <button
              style={{
                position: "absolute",
                right: "1px",
                top: "7vh",
                fontSize: "12px",
                color: "#4eac04",
                width: "5vw",
                height: "3vh",
              }}
              onClick={handleRefresh}
              className="search-button2"
              disabled={loading}
            >
              <i className="fa fa-sync"></i> Actualiser
            </button>
            <button
              style={{
                position: "absolute",
                left: "5.5vw",
                top: "7vh",
                fontSize: "12px",
                color: "tomato",
                width: "5vw",
                height: "3vh",
              }}
              onClick={handleHistory}
              className="search-button2"
              disabled={loading}
            >
              <i className="fa fa-history"></i> Historique
            </button>
          </div>
        </div>

        <div className="partenaires_sect1_bottom_sect_table partenaires_sect1_bottom_sect_table3 ">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Status</th>
                <th>Email</th>
                <th>Points</th>
                <th>Numéro de paiment</th>
                <th>Moyen de paiment</th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <ScaleLoader
                  style={{
                    position: "absolute",
                    top: "20vh",
                    left: "48%",
                  }}
                  color="#4EAC04"
                />
              )}
              {retraits.map((retrait: Retrait) => (
                <tr key={retrait._id}>
                  <td>{retrait._id}</td>
                  <td>
                    {retrait.datePaiement !== null ? (
                      <span style={{ color: "green", fontWeight: "bold" }}>
                        Payé
                      </span>
                    ) : (
                      <span style={{ color: "orange", fontWeight: "bold" }}>
                        En attente
                      </span>
                    )}
                  </td>
                  <td>{retrait.email}</td>
                  <td
                    style={{
                      backgroundColor:
                        retrait.datePaiement == null ? "" : "red",
                    }}
                  >
                    {retrait.datePaiement == null ? "" : "-"}{" "}
                    {retrait.pointRetrait}
                  </td>

                  <td>{retrait.numPaiement}</td>
                  <td>{retrait.moyenPaiement}</td>
                  <td>
                    {" "}
                    <i
                      onClick={() => handleUser(retrait as unknown as User)}
                      className="fa-solid fa-tv"
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pagination-hs">
            <button
              className="pagination-button"
              onClick={() => paginate2(currentPage2 - 1)}
              disabled={currentPage2 === 1}
            >
              <i className="fa fa-angle-left"></i>
            </button>
            <span className="current-page">
              {currentPage2}/{totalPages2}
            </span>
            <button
              className="pagination-button"
              onClick={() => paginate2(currentPage2 + 1)}
              disabled={currentPage2 === totalPages2}
            >
              <i className="fa fa-angle-right"></i>
            </button>
          </div>

          {displayItem && user && (
            <Details
              id={user._id}
              email={user.email}
              pointRetrait={user.pointRetrait}
              numPaiement={user.numPaiement}
              moyenPaiement={user.moyenPaiement}
              dateDemande={user.dateAjout}
              datePaiement={user.datePaiement}
              refPaiment={user.refPaiment}
              onClose={closeChild}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Sect1;
