import React, { useState, useEffect } from "react";
import giga_plus from "./pages/images/giga_plus_logo.png";
import axios from "axios";
import Alert from "@mui/material/Alert";
import validator from "validator";
import { useNavigate } from "react-router-dom";

import loader2 from "./loader2.gif";
import { Cookies, useCookies } from "react-cookie";
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
const Login = () => {
  const [admin, setAdmin] = useState(null);
  const [emailAdmin, setEmailAdmin] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const cookies = new Cookies();

  const handleLogin = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!validator.isEmail(emailAdmin)) {
      setOpen(true);
      setError("Email non conforme!");
      return;
    }

    setLoading(true);

    try {
      const res = await axios.post(`${apiUrl}/api/adminLogin`, {
        emailAdmin,
        password,
      });
      setAdmin(res.data);

      cookies.set("giga_admin_jwtToken", res.data.accessToken);
      cookies.set("giga_admin", res.data);
      setTimeout(() => {
        console.log("Attempting to navigate to /home");
        navigate("/home", { replace: true });
      }, 100);
      /* navigate("/home", { replace: true }); */
    } catch (error) {
      console.error("Login error:", error);
      setLoading(false);
      setOpen(true);
      setError("Email ou mot de pass incorrecte!");
    } finally {
      setLoading(false);
    }
  };

  /*  useEffect(() => {
    if (admin) {
      console.log("Admin state changed, navigating to home");
      navigate("/home", { replace: true });
    }
  }, [admin, navigate]);
 */
  return (
    <div style={{ backgroundColor: "rgb(27, 30, 37)" }} className="login_page">
      <div className="login_page_main">
        <div>
          <img className="login_form_main_logo" src={giga_plus} alt="" />
        </div>
        <div>
          <div className="login_form_field">
            {open && <Alert severity="error"> {error} </Alert>}

            {loading && (
              <img className="login_loader" src={loader2} alt="loading" />
            )}

            <br />

            <input
              onChange={(e) => setEmailAdmin(e.target.value)}
              id="emailAdmin"
              type="email"
              placeholder="Entrer votre email"
              name="emailAdmin"
            />
          </div>

          <div className="login_form_field">
            <input
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              type="password"
              placeholder="Entrer votre mot de pass"
              name="password"
            />
          </div>

          <a href="#">
            {" "}
            <button onClick={handleLogin} className="login_page_main_button">
              {" "}
              Connexion{" "}
            </button>{" "}
          </a>

          <div className="login_form_question_div">
            {" "}
            -&nbsp;
            <a
              style={{
                textDecoration: "none",
                color: "white",
              }}
              href="/adminReset"
            >
              Mot de pass oublié?{" "}
            </a>{" "}
            &nbsp;-{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
