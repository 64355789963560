import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/nav.css";
import "./styles/tool_tip.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "reactjs-popup/dist/index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  React.createElement(React.StrictMode, null, React.createElement(App))
);

reportWebVitals();
