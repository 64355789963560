import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDAHhJiWT1BSfWC2OVVQ7qUNUfSGgtHIrI",
  authDomain: "gigaplusadmin.firebaseapp.com",
  projectId: "gigaplusadmin",
  storageBucket: "gigaplusadmin.appspot.com",
  messagingSenderId: "63336260997",
  appId: "1:63336260997:web:b70df63a5f5123581090ec",
  measurementId: "G-4BFXJ6PZ4E",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);

export { app, analytics, storage };
