import React, { useState, useContext, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import validator from "validator";
import axios from "axios";
import { RegContext } from "../../regContext/RegContext";
/* const { BACKEND_API_URL } = require("../../../../env"); */
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface Retrait {
  id: string;
  usagerParraine: string;
  dateEnregistrement: string;
  nomParrain: string;
}
interface Parrainage {
  _id: string;
  usagerFilleuil: { nom: string; tel: string; email: string };
  usagerParrain: { nom: string; tel: string; email: string };
  dateAjout: string;
}

const Details = (props: any) => {
  const [show, setShow] = useState(false);

  const deleteVideo = () => {
    axios
      .delete(`${apiUrl}/api/video/deleteVideo/${props.idVis}`)
      .then((res) => {
        console.log(res);
        setShow(true);
        window.location.reload();
      })
      .catch((e) => console.log(e));
  };
  const date = new Date(props.dateAjout);

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  } as const;
  const formattedDate = date.toLocaleDateString("fr-FR", options);
  const blockVideo = (id: any) => {
    axios
      .put(`${apiUrl}/api/video/video/${id}/block`)
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((e) => console.log(e));
    console.log("ok block");
  };
  const unBlockVideo = (id: any) => {
    axios
      .put(`${apiUrl}/api/video/video/${id}/unblock`)
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((e) => console.log(e));
    console.log("ok unblock");
  };
  const handleBlockClick = (id: any) => {
    blockVideo(id);
  };
  const handleUnBlockClick = (id: any) => {
    unBlockVideo(id);
  };

  return (
    <div className="sect3_elem_details">
      <div>
        <div onClick={props.onClose} className="elem_details_closure">
          {" "}
          <i className="fa-solid fa-xmark"></i>{" "}
        </div>

        <div className="part_details_div">
          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails du parrainage </b>{" "}
              </span>
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> Nom filleul : &nbsp; </span>{" "}
                <span> {props.nomFilleuil} </span>{" "}
              </div>

              <div>
                <span> Tel filleul : &nbsp; </span>{" "}
                <span> {props.telFilleuil} </span>{" "}
              </div>

              <div>
                <span> Email filleuil : &nbsp; </span>{" "}
                <span> {props.emailFilleuil} </span>{" "}
              </div>

              <div>
                <span> Nom parrain : &nbsp; </span>{" "}
                <span> {props.nomParrain} </span>{" "}
              </div>

              <div>
                <span> Tel parrain : &nbsp; </span>{" "}
                <span> {props.telParrain} </span>{" "}
              </div>
            </div>
          </div>

          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails du parrainage </b>{" "}
              </span>
            </div>

            <div className="row1_details_details">
              <br />
              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> Email parrain : &nbsp; </span>{" "}
                <span> {props.emailParrain} </span>{" "}
              </div>

              <div className="row_status_div">
                <span> Date Ajout: &nbsp; </span>
                <span> {formattedDate} </span>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Sect2 = () => {
  const [nomOperateur, setNomOperateur] = useState([]);
  const [info, setInfo] = useState([]);
  const [displayItem, setDisplayItem] = useState(false);
  const [retraits, setRetraits] = useState<Retrait[]>([]);
  const [total, setTotal] = useState(0);
  const [itemsPerPage2, setItemsPerPage2] = useState(6);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [currentPage2, setCurrentPage2] = useState(1);
  const [totalPages2, setTotalPages2] = useState(0);
  const [hommesSandwich, setHommesSandwich] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [visionnages, setVisionnages] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [parrainages, setParrainages] = useState<Parrainage[]>([]);
  const [user, setUser] = useState<Parrainage | null>(null);

  const closeChild = () => {
    setDisplayItem(false);
  };
  useEffect(() => {
    setLoading(true);
    const limit1 = itemsPerPage2;
    const page = currentPage2;
    axios
      .get(`${apiUrl}/api/parrainage`)
      .then((response) => {
        console.log("parrainages: ", response.data);

        setParrainages(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleUser = (info: Parrainage) => {
    setUser(info);
    setDisplayItem(true);
  };

  return (
    <div className="partenaires_sect2 points_sect2">
      <div className="gestions_sect2_top">
        <div className="partenaires_sect1_bottom_sect_topPoints partenaires_sect1_bottom_sect_topPoints3 partenaires_sect1_bottom_sect_topPoints4">
          <div>Recap partages</div>
          <div className="search-bar-container2">
            <input
              type="text"
              placeholder="Rechercher un usager par son email"
              className="search-bar-hs2"
              value={searchTerm2}
              onChange={(e) => setSearchTerm2(e.target.value)}
            />
            <button
              disabled={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? true
                  : false
              }
              className={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? "search-button-disabled2"
                  : "search-button2"
              }
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>

        <div className="partenaires_sect1_bottom_sect_table partenaires_sect1_bottom_sect_table3 ">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Usager parrainé</th>
                <th>Date d'enregistrement</th>
                <th>Nom parrain</th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {retraits.length === 0 ? (
                <tr>
                  <td
                    colSpan={5}
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    Aucun partage pour l'instant
                  </td>
                </tr>
              ) : (
                retraits.map((retrait: Retrait) => (
                  <tr key={retrait.id}>
                    <td>{retrait.id}</td>
                    <td>{retrait.usagerParraine}</td>
                    <td>{retrait.dateEnregistrement}</td>
                    <td>{retrait.nomParrain}</td>
                    <td>
                      {" "}
                      <i
                        onClick={() =>
                          handleUser(retrait as unknown as Parrainage)
                        }
                        className="fa-solid fa-tv"
                      ></i>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="gestions_sect2_bottom">
        <div className="partenaires_sect1_bottom_sect_topPoints partenaires_sect1_bottom_sect_topPoints3 partenaires_sect1_bottom_sect_topPoints4">
          <div>Recap parrainages</div>
          <div className="search-bar-container2">
            <input
              type="text"
              placeholder="Rechercher un usager parrainé"
              className="search-bar-hs2"
              value={searchTerm2}
              onChange={(e) => setSearchTerm2(e.target.value)}
            />
            <button
              disabled={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? true
                  : false
              }
              className={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? "search-button-disabled2"
                  : "search-button2"
              }
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>

        <div className="partenaires_sect1_bottom_sect_table partenaires_sect1_bottom_sect_table3 ">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Usager parrainé</th>
                <th>Date d'enregistrement</th>
                <th>Nom parrain</th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {parrainages.map((parrainage: Parrainage) => (
                <tr key={parrainage._id}>
                  <td>{parrainage._id}</td>
                  <td>{parrainage.usagerFilleuil.nom}</td>
                  <td>
                    {" "}
                    {new Date(parrainage.dateAjout).toLocaleDateString(
                      "fr-FR",
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour12: false,
                      }
                    )}
                  </td>
                  <td>{parrainage.usagerParrain.nom}</td>

                  <td>
                    {" "}
                    <i
                      onClick={() => handleUser(parrainage)}
                      className="fa-solid fa-tv"
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {displayItem && user && (
            <Details
              id={user._id}
              nomFilleuil={user.usagerFilleuil.nom}
              nomParrain={user.usagerParrain.nom}
              telFilleuil={user.usagerFilleuil.tel}
              telParrain={user.usagerParrain.tel}
              emailFilleuil={user.usagerFilleuil.email}
              emailParrain={user.usagerParrain.email}
              dateAjout={user.dateAjout}
              onClose={closeChild}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Sect2;
