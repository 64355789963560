import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { UserContext } from "../context/UserContext";
import contact from "../../images/perm-contact-calendar.svg";
import userP from "../../images/user-circle.svg";
import { ScaleLoader } from "react-spinners";
import "../../../../styles/nav.css";
import { formatDate } from "../../../../utils/dateUtils";
/* const { BACKEND_API_URL } = require("../../../../env");
const BACKEND_TEST_URL = "https://giga-plus-dev-ec172b1d0071.herokuapp.com"; */
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
interface CountData {
  countUsager: number;
  countMal: number;
  countFemelle: number;
}
interface User {
  _id: string;
  nom: string;
  sexe: string;
  tel: string;
  localisation: string;
  statut: boolean;
  points: number;
  dateAjout: string;
  operateur: string;
  datenaissance: string;
}

const Bilan = (props: any) => {
  return (
    <div className="sect1_bilan  ">
      <div>{props.count}</div>
      <span>{props.title}</span>
    </div>
  );
};
const Bilan1 = (props: any) => {
  return (
    <div className="sect1_bilan sect1_bilanU1  ">
      <div>{props.count}</div>
      <span>{props.title}</span>
    </div>
  );
};
const Bilan2 = (props: any) => {
  return (
    <div className="sect1_bilan sect1_bilanU2  ">
      <div>{props.count}</div>
      <span>{props.title}</span>
    </div>
  );
};

const Details = (props: any) => {
  const [statut, setStatut] = useState(false);
  const [loading, setLoading] = useState(false);
  const blockUser = (id: any) => {
    axios
      .put(`${apiUrl}/api/usager/usagers/${id}/block`)
      .then((res) => {
        setLoading(false);
        window.location.reload();
      })
      .catch((e) => console.log(e));
  };
  const unBlockUser = (id: any) => {
    axios
      .put(`${apiUrl}/api/usager/usagers/${id}/unblock`)
      .then((res) => {
        window.location.reload();
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const handleBlockClick = (id: any) => {
    blockUser(id);
    setLoading(true);
  };
  const Delete = (id: any) => {
    axios
      .delete(`${apiUrl}/api/usager/usagers/${id}`)
      .then((res) => {
        window.location.reload();
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const handleDelete = (id: any) => {
    setLoading(true);
    Delete(id);
  };
  const handleUnBlockClick = (id: any) => {
    unBlockUser(id);
    setLoading(true);
  };
  return (
    <div className="sect3_elem_details">
      <div>
        <div onClick={props.onClose} className="elem_details_closure">
          {" "}
          <i className="fa-solid fa-xmark"></i>{" "}
        </div>

        <div className="part_details_div">
          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails Utilisateur </b>{" "}
              </span>
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails Utilisateur</b>
              </span>

              <div>
                <span> ID : &nbsp; </span> <span> {props.id} </span>{" "}
              </div>
              <div>
                <span> Nom Utilisateur : &nbsp; </span>{" "}
                <span>
                  {" "}
                  {props.nom} &nbsp; <b>S</b>{" "}
                </span>
              </div>
              <div>
                <span> Numero Telephone : &nbsp; </span>{" "}
                <span> {props.tel} </span>{" "}
              </div>
              <div>
                <span> Localisation : &nbsp; </span>{" "}
                <span> {props.localisation} </span>{" "}
              </div>
              <div>
                <span> Sexe : &nbsp; </span> <span> {props.sexe} </span>{" "}
              </div>
              <div>
                <span> Date Naissance : &nbsp; </span>{" "}
                <span> {props.datenaisse} </span>{" "}
              </div>
            </div>
          </div>

          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails Visionnage </b>{" "}
              </span>
            </div>

            <div className="row1_details_details">
              <br />
              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>
              <div>
                <span> Date d'inscription : &nbsp; </span>{" "}
                <span> {formatDate(props.dateAjout)} </span>{" "}
              </div>
              <div>
                <span> Operateur : &nbsp; </span>{" "}
                <span> {props.operateur} </span>{" "}
              </div>
              <div>
                <span> Statut : &nbsp; </span> <span> {props.statut} </span>{" "}
              </div>
              <div>
                <span> Nombre Point : &nbsp; </span>{" "}
                <span> {props.point} </span>{" "}
              </div>

              <div className="row_status_div">
                <span>Choisir le statut:</span>
                <button
                  style={{
                    width: "80px",
                    height: "25px",
                    borderRadius: 7,
                    border: "none",
                    backgroundColor: "red",
                    fontWeight: "bold",
                    color: "#ffff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleBlockClick(props.id)}
                >
                  Bloquer
                </button>
                <button
                  style={{
                    width: "90px",
                    height: "25px",
                    borderRadius: 7,
                    border: "none",
                    backgroundColor: "green",
                    fontWeight: "bold",
                    color: "#ffff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleUnBlockClick(props.id)}
                >
                  Debloquer
                </button>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  style={{
                    width: "100px",
                    height: "25px",
                    borderRadius: 7,
                    border: "none",
                    backgroundColor: "red",
                    fontWeight: "bold",
                    color: "#ffff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDelete(props.id)}
                >
                  Supprimer
                </button>
              </div>
              {loading && (
                <ScaleLoader
                  style={{
                    position: "absolute",
                    top: "45vh",
                    left: "48%",
                  }}
                  color="#4EAC04"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Sect1 = (props: any) => {
  const [nom, setNom] = useState("");
  const [sexe, setSexe] = useState("");
  const [tel, setTel] = useState("");
  const [localisation, setLocalisation] = useState("");
  const [info, setInfo] = useState([]);
  const [displayItem, setDisplayItem] = useState(false);
  const [statut, setStatut] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState<User | null>(null);
  const [count, setCount] = useState<CountData>({
    countUsager: 0,
    countMal: 0,
    countFemelle: 0,
  });
  const closeChild = () => {
    setDisplayItem(false);
  };
  console.log(statut);
  useEffect(() => {
    axios
      .get(
        `${apiUrl}/api/usager/foundUser?nom=${nom}&sexe=${sexe}&localisation=${localisation}&tel=${tel}&statut=${statut}&page=${page}&limit=8`
      )
      .then((response) => {
        console.log(response.data);
        setInfo(response.data.usagers);
        setLoading(false);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
    console.log("ok");

    axios
      .get(`${apiUrl}/api/usager/count`)
      .then((response) => {
        console.log(response.data);
        setCount(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [nom, sexe, localisation, tel, statut, page]);

  const handlePageChange = (newPage: any) => {
    if (newPage > 0 && newPage <= totalPages) {
      setLoading(true);

      setPage(newPage);
    }
  };
  const handleUser = (info: User) => {
    setUser(info);
    setDisplayItem(true);
  };
  return (
    <div className="geneUser">
      <div className="partenaires_sect1">
        <div className="partenaires_sect1_top">
          <Bilan title="Utilisateurs total" count={count.countUsager} />
          <Bilan1 title="Masculin " count={count.countMal} />
          <Bilan2 title="Feminin " count={count.countFemelle} />
        </div>

        <div className="partenaires_sect1_bottom">
          <div className="partenaires_sect1_bottom_sect_top">
            <div></div>
            <div>Liste Utilisateur</div>
          </div>

          <div
            className="partenaires_sect1_bottom_sect_table"
            style={{ overflowY: "scroll", height: "300px" }}
          >
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nom</th>
                  <th>Sexe</th>
                  <th>Numero</th>
                  <th>Commune</th>
                  <th>Statut</th>
                  <th> Action </th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <ScaleLoader
                    style={{
                      position: "absolute",
                      top: "20vh",
                      left: "48%",
                    }}
                    color="#4EAC04"
                  />
                )}
                {info.map((info: any) => (
                  <tr key={info._id}>
                    <td>{info._id}</td>
                    <td>{info.nom}</td>
                    <td>{info.sexe}</td>
                    <td>{info.tel}</td>
                    <td>{info.localisation}</td>
                    <td>{info.statut ? "Debloquer" : "Bloquer"}</td>
                    <td>
                      {" "}
                      <i
                        onClick={() => handleUser(info)}
                        className="fa-solid fa-tv"
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              <button
                className="pagination-button"
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1}
              >
                <i className="fa fa-angle-left"></i>
              </button>
              <span>
                {page}/{totalPages}
              </span>
              <button
                className="pagination-button"
                onClick={() => handlePageChange(page + 1)}
                disabled={page === totalPages}
              >
                <i className="fa fa-angle-right"></i>
              </button>
            </div>

            {displayItem && user && (
              <Details
                id={user._id}
                nom={user.nom}
                sexe={user.sexe}
                tel={user.tel}
                localisation={user.localisation}
                datenaisse={user.datenaissance}
                operateur={user.operateur}
                statut={user.statut ? "Debloquer" : "Bloquer"}
                point={user.points}
                dateAjout={user.dateAjout}
                onClose={closeChild}
              />
            )}
          </div>
        </div>
      </div>
      <div className="user_sect2">
        <div className="user_sect2_part_top">
          <div> Profil utilisateur selectionné </div>
        </div>

        <div className="user_sect2_part_bottom">
          <div className="user_add_form">
            <div>
              <div className="icon-user">
                {" "}
                <img src={userP} />
              </div>

              <div className="user_form_field">
                <input
                  id="nom"
                  type="text"
                  placeholder="Nom complet"
                  name=""
                  onChange={(e) => setNom(e.target.value)}
                />
              </div>
              <div className="user_form_field">
                <input
                  id="numero"
                  type="text"
                  placeholder="Numero"
                  name=""
                  onChange={(e) => setTel(e.target.value)}
                />
              </div>

              <div className="user_form_field">
                <input
                  id="commune"
                  type="text"
                  placeholder="Commune"
                  name=""
                  onChange={(e) => setLocalisation(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="box-filter">
          <div className="range-age">
            <div className="filter">
              <h4>Filtrer</h4>
              <img src={contact} width="22px" />
            </div>

            <label>Sexe</label>
          </div>
          <div className="but-sexe">
            <button
              value="masculin"
              /*  onClick={(e) => setSexe(e.target.value)} */
              onClick={(e) => setSexe((e.target as HTMLButtonElement).value)}
              style={{ cursor: "pointer" }}
            >
              Masculin
            </button>
            <button
              value="féminin"
              onClick={(e) => setSexe((e.target as HTMLButtonElement).value)}
              style={{ cursor: "pointer" }}
            >
              Feminin
            </button>
          </div>
          <label
            style={{
              fontWeight: "bold",
              color: "#ffff",
              position: "relative",
              right: 80,
              marginTop: 10,
            }}
          >
            Statut Utilisateur
          </label>

          <div className="but-sexe" style={{ marginTop: 20 }}>
            <button
              value="false"
              onClick={(e) => setStatut((e.target as HTMLButtonElement).value)}
              style={{ cursor: "pointer" }}
            >
              Bloquer
            </button>
            <button
              value="true"
              onClick={(e) => setStatut((e.target as HTMLButtonElement).value)}
              style={{ cursor: "pointer", marginRight: -28 }}
            >
              Non Bloquer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sect1;
