import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { ScaleLoader } from "react-spinners";
import PayementHeader from "../../composants/PayementHeader";
import Tooltip from "@mui/material/Tooltip";
import PaymentTable from "../../composants/PaymentTable";
/* const BACKEND_TEST_URL = "https://giga-plus-dev-ec172b1d0071.herokuapp.com"; */
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
const Panierpayement = () => {
  const [payments, setPayments] = useState([]);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchPayments = async () => {
    setLoading(true);
    try {
      const regularPaymentsResponse = await axios.get(
        `${apiUrl}/api/payments/getallpayments`
      );

      const cinetpayPaymentsResponse = await axios.get(
        `${apiUrl}/api/cynetpay/getpayments`
      );

      const regularPayments = regularPaymentsResponse.data.payments || [];
      const cinetpayPayments = Array.isArray(cinetpayPaymentsResponse.data)
        ? cinetpayPaymentsResponse.data
        : cinetpayPaymentsResponse.data.payments || [];

      const combinedPayments = [...regularPayments, ...cinetpayPayments];
      combinedPayments.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      setPayments(combinedPayments as React.SetStateAction<never[]>);
    } catch (error) {
      console.error("Error fetching payments:", error);

      setPayments([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPayments();
    fetchStats();
  }, []);

  const fetchStats = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/payments/getPaymentStats`
      );
      setStats(response.data);
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };

  const handleViewDetails = (payment: any) => {
    console.log("View details for payment:", payment);
  };

  return (
    <section className="dashboard">
      <PayementHeader />
      <PaymentTable
        payments={payments}
        loading={loading}
        fetchPayments={fetchPayments}
      />
    </section>
  );
};

export default Panierpayement;
