import React from "react";
import Sect1 from "./sub/points/Sect1";
import Sect2 from "./sub/points/Sect2";

const Points = () => {
  return (
    <section className="points">
      <Sect1 />
      <Sect2 />
    </section>
  );
};

export default Points;
