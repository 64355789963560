import React, { useState, useEffect, useContext, createContext } from "react";
import "../../styles/Visionnages.css";
import video from "./images/video-library.svg";
import Sect1 from "./sub/Visionnages/Sect1";
import axios from "axios";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import info from "./images/information-slab-circle.svg";
import { ScaleLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/* const { BACKEND_API_URL } = require("../../env");
const BACKEND_TEST_URL = "https://giga-plus-dev-ec172b1d0071.herokuapp.com"; */
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
interface CurrVisionnage {
  _id: string;
  idVideo?: string;
  usager?: { nom: string };
  pointDonnee: number;
  partenaire?: { nomPartenaire: string };
  duréeVideo?: number;
  dateVisionnage: Date;
  OperateurDonneur: string;
  achatUrl: string;
}

interface Visionnage {
  _id: string;
  usager?: { nom: string };
  pointDonnee: number;
  dateVisionnage: Date;
  video?: { intitulee: string };
  OperateurDonneur: string;
}

const Bilan = (props: any) => {
  return (
    <div className="sect1_bilan">
      <div>{props.count}</div>
      <span>{props.title}</span>
    </div>
  );
};
const Bilan1 = (props: any) => {
  return (
    <div className="sect1_bilan sect1_bilanU1  ">
      <div>{props.count}</div>
      <span>{props.title}</span>
    </div>
  );
};

const Details2 = (props: any) => {
  // const [displayItem, setDisplayItem] = useState(true)
  return (
    <div className="sect3_elem_details">
      <div>
        {/* <FontAwesomeIcon  style={{color:"black"}}  icon="fa-solid fa-circle-xmark" /> */}
        <div onClick={props.onClose} className="elem_details_closure">
          {" "}
          <i className="fa-solid fa-xmark"></i>{" "}
        </div>

        <div className="part_details_div">
          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails Visionnage </b>{" "}
              </span>
              {/* <span> {props.partDescription} </span> */}
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> ID : &nbsp; </span> <span> {props.idVis} </span>{" "}
              </div>
              <div>
                <span> Durée Vidéo : &nbsp; </span>{" "}
                <span>
                  {" "}
                  {props.dureeVidVis} &nbsp; <b>S</b>{" "}
                </span>
              </div>
              <div>
                <span> Points offerts : &nbsp; </span>{" "}
                <span> {props.gigaVis}</span>{" "}
              </div>

              <div>
                <span> Nom partenaire : &nbsp; </span>{" "}
                <span> {props.idPartVis} </span>{" "}
              </div>
              <div>
                <span> Nom usager : &nbsp; </span>{" "}
                <span> {props.idUsagerVis} </span>{" "}
              </div>
              {/* <div>
                <span> Url achat : &nbsp; </span>{" "}
                <span> {props.achatUrl} </span>{" "}
              </div> */}
            </div>
          </div>

          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails Visionnage </b>{" "}
              </span>
            </div>

            <div className="row1_details_details">
              <br />
              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> ID_vidéo : &nbsp; </span> <span> {props.idVidVis} </span>{" "}
              </div>
              <div>
                <span> Date Visionnage : &nbsp; </span>{" "}
                <span>
                  {" "}
                  {new Date(props.dateVis).toLocaleString("fr-CI", {
                    day: "numeric",
                    month: "numeric",
                    year: "2-digit",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    timeZone: "Africa/Abidjan",
                  })}{" "}
                </span>{" "}
              </div>

              <div className="row_status_div"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const UploadContext = createContext<{
  uploadProgress: number;
  isUploading: boolean;
  addToUploadQueue: (formData: FormData) => void;
  uploadComplete: boolean;
  setIsUploading: (isUploading: boolean) => void;
  setUploadProgress: (uploadProgress: number) => void;
}>({
  uploadProgress: 0,
  isUploading: false,
  addToUploadQueue: () => {},
  uploadComplete: false,
  setIsUploading: () => {},
  setUploadProgress: () => {},
});

const UploadProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadQueue, setUploadQueue] = useState<FormData[]>([]);
  const [uploadComplete, setUploadComplete] = useState(false);

  const addToUploadQueue = (formData: FormData) => {
    setUploadQueue((prev) => [...prev, formData]);
  };

  useEffect(() => {
    const processQueue = async () => {
      if (uploadQueue.length > 0 && !isUploading) {
        setIsUploading(true);
        setUploadProgress(0);
        const formData = uploadQueue[0];

        try {
          await axios.post(
            `${apiUrl}/api/video/addVideoByPartenaire`,
            formData,
            {
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / (progressEvent.total || 1)
                );
                setUploadProgress(percentCompleted);
              },
            }
          );
          setUploadComplete(true);
          toast.success("Vidéo envoyée avec succès !");
        } catch (error) {
          toast.error("Une erreur s'est produite lors de l'envoi de la vidéo.");
        } finally {
          setIsUploading(false);
          setUploadQueue((prev) => prev.slice(1));
        }
      }
    };

    processQueue();
  }, [uploadQueue, isUploading]);

  return (
    <UploadContext.Provider
      value={{
        uploadProgress,
        isUploading,
        addToUploadQueue,
        uploadComplete,
        setIsUploading,
        setUploadProgress,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};

const useUpload = () => useContext(UploadContext);

// Progress component to show upload progress
function CircularProgressWithLabel(props: any) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

// Progress component to show upload progress
const UploadProgress = () => {
  const { uploadProgress, isUploading } = useUpload();

  if (!isUploading) return null;

  return (
    <div className="fixed bottom-4 right-4 bg-white p-4 rounded-lg shadow-lg elevation-6">
      <p>Envoie de la vidéo: {uploadProgress}%</p>
      <CircularProgressWithLabel value={uploadProgress} />
    </div>
  );
};

const AddVideo = (props: any) => {
  const [partenaires, setPartenaires] = useState([]);
  const [packVideo, setPackVideo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [video, setVideo] = useState(null);
  const [choosePart, setChoosePart] = useState("");
  const [chooseParts, setChooseParts] = useState("");
  const [duree, setDuree] = useState("");
  const [nbDatas, setNbDatas] = useState("");
  const [achatUrl, setAchatUrl] = useState("");
  const [intitule, setIntitule] = useState("");
  const [show, setShow] = useState(false);
  const [totalVideo, setTotalVideo] = useState(0);
  const [prixCampagneVideo, setPrixCampagneVideo] = useState("");
  const {
    uploadProgress,
    isUploading,
    addToUploadQueue,
    setIsUploading,
    setUploadProgress,
  } = useUpload();

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/PackVideo/getPackVideo`)
      .then((res) => {
        setPackVideo(res.data);
      })
      .catch((e) => console.log(e));
    axios
      .get(`${apiUrl}/api/partenaires`, {
        params: {
          search: searchTerm,
        },
      })
      .then((res) => {
        console.log(res.data.partenaires);
        setPartenaires(res.data.partenaires);
      })
      .catch((e) => console.log(e));
    axios
      .get(`${apiUrl}/api/getTotal`)
      .then((res) => {
        setTotalVideo(res.data);
      })
      .catch((e) => console.log(e));
  }, []);

  const addVideo = () => {
    if (
      !duree ||
      !nbDatas ||
      !intitule ||
      !prixCampagneVideo ||
      !partenaires ||
      !video
    ) {
      toast.error("Tous les champs sont obligatoires.");
      return;
    }

    const formData = new FormData();
    formData.append("duree", duree);
    formData.append("idPartenaire", choosePart);
    formData.append("video", video);
    formData.append("nbDatas", nbDatas);
    formData.append("intitule", intitule);
    formData.append("prixCampagneVideo", prixCampagneVideo);
    formData.append("achatUrl", achatUrl);

    addToUploadQueue(formData);
    if (props.onClose) props.onClose();

    setIsUploading(true);
    setUploadProgress(0);
    axios
      .post(`${apiUrl}/api/video/addVideoByPartenaire`, formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 1)
          );
          setUploadProgress(percentCompleted);
        },
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setIsUploading(false);
        setUploadProgress(0);
        toast.success("Vidéo envoyée avec succès !");
      })
      .catch((e) => {
        setLoading(false);
        setIsUploading(false);
        setUploadProgress(0);
        toast.error("Une erreur s'est produite lors de l'envoi de la vidéo.");
      });
  };

  const handleDureeChange = (e: any) => {
    const originalValue = e.target.value;
    const cleanedValue = originalValue.replace(/[^0-9.]/g, "");

    if (originalValue !== cleanedValue) {
      toast.error("La durée doit être un nombre.");
    }

    setDuree(cleanedValue);
  };

  const handleNbDatasChange = (e: any) => {
    const originalValue = e.target.value;
    const cleanedValue = originalValue.replace(/[^0-9.]/g, "");

    if (originalValue !== cleanedValue) {
      toast.error("Le crédit de visionnage doit être un nombre.");
    }

    setNbDatas(cleanedValue);
  };

  const handlePrixChange = (e: any) => {
    const originalValue = e.target.value;
    const cleanedValue = originalValue.replace(/[^0-9.]/g, "");

    if (originalValue !== cleanedValue) {
      toast.error("Le prix de la campagne vidéo doit être un nombre.");
    }

    setPrixCampagneVideo(cleanedValue);
  };

  const handleIntituleChange = (e: any) => {
    setIntitule(e.target.value);
  };
  const handleachaturl = (e: any) => {
    setAchatUrl(e.target.value);
  };

  return (
    <div className="sect3_elem_detail p-4 bg-white w-full  ">
      <div>
        <div
          onClick={props.onClose}
          className="elem_details_closure cursor-pointer text-gray-500 hover:text-red-500 transition-colors"
        >
          <i className="fa-solid fa-xmark text-2xl"></i>
        </div>

        <div className="mt-8">
          <div>
            <div className="row1_details_header flex items-center justify-between">
              {show && (
                <Alert
                  severity="success"
                  className="absolute top-[-150px] left-[230px] transition-opacity duration-500"
                >
                  Ajout de video effectué
                </Alert>
              )}
              <span className="text-black font-bold text-lg">
                Ajout De Video
              </span>
            </div>

            <div className="row1_details_detail mt-4 space-y-4">
              <div className="w-full mb-5">
                <label className="block text-lg ml-4 mr-4 font-semibold text-gray-700 mb-2 whitespace-nowrap">
                  Durée Vidéo
                </label>
                <input
                  className="w-full px-4 py-3 rounded-lg border-2 border-gray-300 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-200 transition duration-200 ease-in-out text-gray-900"
                  placeholder="Entrez la durée en secondes..."
                  type="text"
                  value={duree}
                  onChange={handleDureeChange}
                />
              </div>

              <div>
                <label className="block text-lg ml-4 mr-4 font-semibold text-gray-700 mb-2 whitespace-nowrap">
                  Crédit visionnage
                </label>
                <input
                  className="w-full px-4 py-3 rounded-lg border-2 border-gray-300 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-200 transition duration-200 ease-in-out text-gray-900"
                  placeholder="le gain des utilisateurs après avoir regardé la vidéo"
                  type="text"
                  value={nbDatas}
                  onChange={handleNbDatasChange}
                />
              </div>

              <div>
                <label className="block text-lg ml-4 mr-4 font-semibold text-gray-700 mb-2 whitespace-nowrap">
                  Intitule de la video
                </label>
                <input
                  className="w-full px-4 py-3 rounded-lg border-2 border-gray-300 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-200 transition duration-200 ease-in-out text-gray-900"
                  placeholder="..."
                  type="text"
                  value={intitule}
                  onChange={handleIntituleChange}
                />
              </div>

              <div>
                <label className="block text-lg ml-4 mr-4 font-semibold text-gray-700 mb-2 whitespace-nowrap">
                  Montant Video
                </label>
                <input
                  className="w-full px-4 py-3 rounded-lg border-2 border-gray-300 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-200 transition duration-200 ease-in-out text-gray-900"
                  placeholder="..."
                  type="text"
                  value={prixCampagneVideo}
                  onChange={handlePrixChange}
                />
              </div>

              <div>
                <label className="block text-lg ml-4 mr-4 font-semibold text-gray-700 mb-2 whitespace-nowrap">
                  Url du produit de la video
                </label>
                <input
                  className="w-full px-4 py-3 rounded-lg border-2 border-gray-300 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-200 transition duration-200 ease-in-out text-gray-900"
                  placeholder="ajoutez l'url du produit..."
                  type="text"
                  value={achatUrl}
                  onChange={handleachaturl}
                />
              </div>

              <div>
                <label className="block font-medium text-gray-700">
                  Video à Ajouté :
                </label>
                <input
                  className="video_file mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  type="file"
                  name="duree"
                  accept=".mp4,.avi,video/mp4,video/x-msvideo"
                  onChange={(e: any) => setVideo(e.target.files[0])}
                />
                <p className="mt-1 text-sm text-gray-500">
                  Formats acceptés : MP4, AVI
                </p>
              </div>

              <div>
                <label className="block font-medium text-gray-700">
                  Partenaire
                </label>

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={partenaires}
                  size="small"
                  sx={{ width: 250 }}
                  getOptionLabel={(option: any) => option.nomPartenaire || ""}
                  renderInput={(params) => (
                    <TextField {...params} label="Partenaire" />
                  )}
                  onChange={(e: any, value: any) =>
                    setChoosePart(value ? value._id : null)
                  }
                />
              </div>

              <button
                className="mt-5 self-center w-28 h-10 rounded-md bg-gray-800 text-white font-bold hover:bg-gray-700 transition-colors"
                onClick={() => addVideo()}
              >
                AJOUTER
              </button>

              {loading && (
                <ScaleLoader
                  className="absolute top-[50vh] left-[48%]"
                  color="#00ff00"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Visionnages() {
  const [displayItem, setDisplayItem] = useState(false);
  const [displayItem2, setDisplayItem2] = useState(false);
  const closeChild2 = () => {
    setDisplayItem2(false);
  };

  const closeChild = () => {
    setDisplayItem(false);
  };

  const [totalVideo, setTotalVideo] = useState(0);
  const [totalVision, setTotalVision] = useState(0);
  useEffect(() => {
    axios
      .get(`${apiUrl}/api/video/getTotal`)
      .then((res) => {
        console.log(res.data);
        setTotalVideo(res.data.total);
      })
      .catch((e) => console.log(e));
    axios
      .get(`${apiUrl}/api/visionnage/getTotal`)
      .then((res) => {
        console.log(res.data);
        setTotalVision(res.data.total);
      })
      .catch((e) => console.log(e));
  }, []);
  const [currVisionnage, setCurrVisionnage] = useState({});
  const [recherche, setRecherche] = useState("");
  const [loading, setLoading] = useState(true);
  const [visionnages, setVisionnages] = useState<Visionnage[]>([]);
  const [currHommeSandwich, setCurrHommeSandwich] = useState<CurrVisionnage>(
    {} as CurrVisionnage
  );
  const [itemsPerPage2, setItemsPerPage2] = useState(6);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [currentPage2, setCurrentPage2] = useState(1);
  const [totalPages2, setTotalPages2] = useState(0);
  const [hommesSandwich, setHommesSandwich] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { uploadComplete } = useContext(UploadContext);

  useEffect(() => {
    setLoading(true);
    const limit1 = itemsPerPage2;
    const page = currentPage2;
    axios
      .get(`${apiUrl}/api/visionnages?limit=${limit1}&page=${page}`)
      .then((response) => {
        setVisionnages(response.data.viewings);
        setTotalPages2(response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [recherche]);

  /*  const handleVisionnage = (visionnage: Visionnage) => {
    setCurrHommeSandwich(visionnage);
    setDisplayItem2(true);
  }; */
  const handleVisionnage = (visionnage: {
    _id: string;
    usager?: { nom: string };
    OperateurDonneur: string;
    pointDonnee: number;
    dateVisionnage: Date;
    video?: { intitulee: string };
  }) => {
    setCurrHommeSandwich(visionnage as CurrVisionnage);
    setDisplayItem2(true);
  };

  const paginate2 = (pageNumber: number) => {
    setCurrentPage2(pageNumber);
    setLoading(true);
    const limit1 = itemsPerPage2;
    const page = pageNumber;
    Promise.all([
      axios.get(`${apiUrl}/api/visionnages?limit=${limit1}&page=${page}`),
    ])
      .then(([dataVisionnage]) => {
        setVisionnages(dataVisionnage.data.viewings);
        setTotalPages2(dataVisionnage.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchHandler2 = (e: any) => {
    setLoading(true);
    const limit1 = itemsPerPage2;
    const page = currentPage2;

    console.log("searchTerm2: " + searchTerm2);
    Promise.all([
      axios.get(
        `${apiUrl}/api/visionnages?limit=${limit1}&page=${page}&search=${searchTerm2}`
      ),
    ])

      .then(([dataVisionnage]) => {
        setVisionnages(dataVisionnage.data.viewings);
        setTotalPages2(dataVisionnage.data.totalPages);
        setLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="body-gene">
        <div className="view-vision">
          <div className="information-vision">
            <Bilan title="Nombre total de video" count={totalVideo} />
            <Bilan1 title="Nombre total de visionnage " count={totalVision} />
          </div>
          <div className="list-vision">
            <Sect1 uploadComplete={uploadComplete} />
          </div>
        </div>

        <div className="view-form">
          <div className="form">
            <div className="log-video">
              <span>Ajouter une video</span>
            </div>

            <button
              className="log-videoButton"
              onClick={(e) => setDisplayItem(true)}
            >
              <i className="fa-regular fa-circle-play"></i>
            </button>
            <div className="part-choose"></div>

            {displayItem && <AddVideo onClose={closeChild} />}
          </div>

          <div className="visionnagesonform">
            <div className="partenaires_sect1_bottom partenaires_sect1_bottom2 ">
              <div
                className="partenaires_sect1_bottom_sect_top"
                style={{ height: 50 }}
              >
                <div>Recap visionnages</div>

                <div className="search-bar-container2">
                  <input
                    type="text"
                    placeholder="Rechercher un visionnage par 'intitule video' "
                    className="search-bar-hs2"
                    value={searchTerm2}
                    onChange={(e) => setSearchTerm2(e.target.value)}
                  />
                  <button
                    onClick={searchHandler2}
                    disabled={
                      (loading && searchTerm2.length > 0) ||
                      (!loading && searchTerm2.length == 0)
                        ? true
                        : false
                    }
                    className={
                      (loading && searchTerm2.length > 0) ||
                      (!loading && searchTerm2.length == 0)
                        ? "search-button-disabled2"
                        : "search-button2"
                    }
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>

              <div
                className="partenaires_sect1_bottom_sect_table partenaires_sect1_bottom_sect_table2"
                style={{
                  height: "350px",
                  width: "100%",
                }}
              >
                <table
                  style={{
                    width: "70%",
                  }}
                >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nom usager</th>
                      <th>Points offerts</th>
                      <th>Date visionnage</th>
                      <th>Intitule video</th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <ScaleLoader
                        style={{
                          position: "absolute",
                          top: "20vh",
                          left: "48%",
                        }}
                        color="#4EAC04"
                      />
                    )}
                    {visionnages.map((visionnage: Visionnage) => (
                      <tr key={visionnage._id}>
                        <td>{visionnage._id}</td>

                        <td>{visionnage.usager?.nom ?? "Null"}</td>
                        <td>{visionnage.pointDonnee}</td>
                        <td>
                          {new Date(
                            visionnage.dateVisionnage
                          ).toLocaleDateString("fr-FR", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour12: false,
                          })}
                        </td>
                        <td>{visionnage.video?.intitulee}</td>

                        <td>
                          <i
                            onClick={() => handleVisionnage(visionnage)}
                            className="fa-solid fa-tv"
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="pagination-hs">
                  <button
                    className="pagination-button"
                    onClick={() => paginate2(currentPage2 - 1)}
                    disabled={currentPage2 === 1}
                  >
                    <i className="fa fa-angle-left"></i>
                  </button>
                  <span className="current-page">
                    {currentPage2}/{totalPages2}
                  </span>
                  <button
                    className="pagination-button"
                    onClick={() => paginate2(currentPage2 + 1)}
                    disabled={currentPage2 === totalPages2}
                  >
                    <i className="fa fa-angle-right"></i>
                  </button>
                </div>

                {displayItem2 && (
                  <Details2
                    idVis={currHommeSandwich._id}
                    idVidVis={currHommeSandwich.idVideo}
                    idUsagerVis={currHommeSandwich.usager?.nom ?? "Null"}
                    gigaVis={currHommeSandwich.pointDonnee}
                    opeVis={currHommeSandwich.OperateurDonneur}
                    idPartVis={
                      currHommeSandwich.partenaire?.nomPartenaire ?? "Null"
                    }
                    dureeVidVis={currHommeSandwich.duréeVideo}
                    dateVis={currHommeSandwich.dateVisionnage}
                    /*   achatUrl={currHommeSandwich.achatUrl} */
                    onClose={closeChild2}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { AddVideo, UploadProgress, UploadProvider };
