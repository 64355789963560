import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  IconButton,
  Snackbar,
  Alert,
  Tooltip,
  AlertColor,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import { formatDate } from "../utils/dateUtils";

/* const BACKEND_TEST_URL = "https://giga-plus-dev-ec172b1d0071.herokuapp.com"; */
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
interface Payment {
  _id: string;
  transactionId?: string;
  customerName: string;
  transactioncode: string;
  amount: number;
  currency: string;
  description: string;
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const PaymentTable = ({
  payments,
  loading,
  fetchPayments,
}: {
  payments: any;
  loading: boolean;
  fetchPayments: () => void;
}) => {
  const [selectedPayment, setSelectedPayment] = useState<Payment | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleViewDetails = (payment: any) => {
    setSelectedPayment(payment);
    setNewStatus(payment.status);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedPayment(null);
  };

  const handleStatusChange = async () => {
    if (!selectedPayment) {
      console.error("No payment selected");
      setSnackbar({
        open: true,
        message: "Aucun paiement sélectionné",
        severity: "error",
      });
      return;
    }

    try {
      let endpoint;
      if (selectedPayment?.transactionId) {
        endpoint = `${apiUrl}/api/cynetpay/updatepaymentstatus/${selectedPayment._id}`;
      } else {
        endpoint = `${apiUrl}/api/payments/payments/${selectedPayment._id}`;
      }

      console.log(
        `Updating payment status. Endpoint: ${endpoint}, New status: ${newStatus}`
      );

      const response = await axios.put(endpoint, { status: newStatus });

      console.log("Update response:", response.data);

      await fetchPayments();
      handleModalClose();
      setSnackbar({
        open: true,
        message: "Statut de paiement mis à jour avec succès",
        severity: "success",
      });
    } catch (error: any) {
      console.error(
        "Erreur lors de la mise à jour du statut de paiement:",
        error
      );
      console.error("Error details:", error.response?.data || error.message);
      setSnackbar({
        open: true,
        message: `Échec de la mise à jour du statut de paiement: ${
          error.response?.data?.message || error.message
        }`,
        severity: "error",
      });
    }
  };

  const handleDelete = async () => {
    if (!selectedPayment) {
      console.error("No payment selected for deletion");
      setSnackbar({
        open: true,
        message: "Aucun paiement sélectionné pour la suppression",
        severity: "error",
      });
      return;
    }

    try {
      let endpoint;
      if (selectedPayment?.transactionId) {
        endpoint = `${apiUrl}/api/cinetpay/deletepayment/${selectedPayment._id}`;
      } else {
        endpoint = `${apiUrl}/api/payments/deletepayment/${selectedPayment._id}`;
      }

      console.log(`Attempting to delete payment. Endpoint: ${endpoint}`);

      const response = await axios.delete(endpoint);

      console.log("Delete response:", response.data);

      await fetchPayments();
      handleModalClose();
      setSnackbar({
        open: true,
        message: "Paiement supprimé avec succès",
        severity: "success",
      });
    } catch (error: any) {
      console.error("Erreur lors de la suppression du paiement:", error);
      console.error("Error details:", error.response?.data || error.message);
      setSnackbar({
        open: true,
        message: `Erreur lors de la suppression du paiement: ${
          error.response?.data?.message || error.message
        }`,
        severity: "error",
      });
    }
  };

  const handleCopyTransactionCode = (code: any) => {
    navigator.clipboard.writeText(code).then(
      () => {
        setSnackbar({
          open: true,
          message: "Code de transaction copié",
          severity: "success",
        });
      },
      (err) => {
        console.error("Erreur lors de la copie: ", err);
        setSnackbar({
          open: true,
          message: "Échec de la copie du code",
          severity: "error",
        });
      }
    );
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setSnackbar({ ...snackbar, open: false });
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className="bg-gray-50">
              <TableCell className="font-semibold">Index</TableCell>
              <TableCell className="font-semibold">Nom du panier</TableCell>
              <TableCell className="font-semibold">Client</TableCell>
              <TableCell className="font-semibold">Type de paiement</TableCell>
              <TableCell className="font-semibold">Montant</TableCell>
              <TableCell className="font-semibold">Date</TableCell>
              <TableCell className="font-semibold">Statut</TableCell>
              <TableCell className="font-semibold">
                Liste des produits
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((payment: any, index: any) => (
              <TableRow key={payment._id}>
                <TableCell>
                  <IconButton
                    onClick={() => handleViewDetails(payment)}
                    color="primary"
                    size="small"
                  >
                    <VisibilityIcon /> {index + 1}
                  </IconButton>
                </TableCell>
                <TableCell>{payment.paniername}</TableCell>
                <TableCell>{payment.name || payment.customerName}</TableCell>
                <TableCell>{payment.paymentMethod}</TableCell>
                <TableCell>
                  {payment.amount} {payment.currency}
                </TableCell>
                <TableCell>{formatDate(payment.createdAt)}</TableCell>
                <TableCell>
                  <span
                    style={{
                      color:
                        payment.status === "En attente"
                          ? "orange"
                          : payment.status === "Échec"
                          ? "red"
                          : payment.status === "Terminé"
                          ? "green"
                          : "inherit",
                    }}
                  >
                    {payment.status || "N/A"}
                  </span>
                </TableCell>
                <TableCell>
                  {Array.isArray(payment.description)
                    ? payment.description.join(", ")
                    : payment.description}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="payment-details-modal"
      >
        <Box sx={modalStyle}>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2" gutterBottom>
            Détails du paiement
          </Typography>
          {selectedPayment ? (
            <>
              <Typography>
                <strong>Nom du client:</strong> {selectedPayment.customerName}
              </Typography>
              <Typography>
                <strong>Code client:</strong> {selectedPayment.transactioncode}
                <Tooltip title="Copier le code">
                  <IconButton
                    onClick={() =>
                      handleCopyTransactionCode(selectedPayment.transactioncode)
                    }
                    size="small"
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Typography>

              <Typography>
                <strong>Montant:</strong> {selectedPayment.amount}{" "}
                {selectedPayment.currency}
              </Typography>
              <Typography>
                <strong>Description:</strong>{" "}
                {Array.isArray(selectedPayment.description)
                  ? selectedPayment.description.join(", ")
                  : selectedPayment.description}
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography component="span">
                  <strong>Status:</strong>
                </Typography>
                <Select
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                  sx={{ ml: 1, minWidth: 120 }}
                >
                  <MenuItem value="En attente">En attente</MenuItem>
                  <MenuItem value="Terminé">Terminé</MenuItem>
                  <MenuItem value="Échec">Échec</MenuItem>
                </Select>
              </Box>
              <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={handleModalClose} sx={{ mr: 1 }}>
                  Fermer
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleStatusChange}
                >
                  Mettre à jour le statut
                </Button>
              </Box>
            </>
          ) : (
            <Typography>Chargement des détails du paiement...</Typography>
          )}
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity as AlertColor}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PaymentTable;
