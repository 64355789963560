import React, { useState } from "react";
import { FaPlus, FaTrash, FaEdit, FaUpload } from "react-icons/fa";
import { Formik, FieldArray, Form as FormikForm, Field } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import ImageSelector from "./ImageSelector";

interface CategoryProps {
  handleUpdateCategory: (category: any) => void;
  handleCreateCategory: (category: any) => void;
  editingCategory: any | null;
  setEditingCategory: React.Dispatch<React.SetStateAction<any | null>>;
}

function CreationProduitForm({
  handleUpdateCategory,
  handleCreateCategory,
  editingCategory,
  setEditingCategory,
}: CategoryProps) {
  const [uploading, setUploading] = useState(false);

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg max-w-4xl mx-auto">
      <Formik
        initialValues={{
          name: editingCategory?.name || "",
          color: editingCategory?.color || "",
          namepartenaire: editingCategory?.namepartenaire || "",
          buckets: editingCategory?.buckets || [
            {
              name: "",
              image: "",
              price: "",
              prixnormal: "",
              products: [{ name: "", image: "" }],
            },
          ],
        }}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          if (editingCategory) {
            handleUpdateCategory(values);
          } else {
            handleCreateCategory(values);
          }
          resetForm();
          setEditingCategory(null);
        }}
      >
        {({ values, setFieldValue }) => (
          <FormikForm className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-gray-700 font-medium">
                Nom de la catégorie
              </label>
              <Field
                name="name"
                placeholder="Nom de la catégorie"
                className="mt-2 w-full p-3 border rounded-md"
              />
            </div>
            {/*  <div>
              <label htmlFor="name" className="block text-gray-700 font-medium">
                Nom dupartenaire
              </label>
              <Field
                name="namepartenaire"
                placeholder="Nom du partenaire"
                className="mt-2 w-full p-3 border rounded-md"
              />
            </div> */}

            <FieldArray name="buckets">
              {({ push, remove }) => (
                <div className="space-y-6">
                  <AnimatePresence>
                    {values.buckets.map(
                      (_: any, index: number, bucket: any) => (
                        <motion.div
                          key={index}
                          initial={{ opacity: 0, y: -20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -20 }}
                          transition={{ duration: 0.3 }}
                          className="p-4 border rounded-lg bg-gray-50"
                        >
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <label
                                htmlFor={`buckets.${index}.name`}
                                className="block text-gray-700 font-medium"
                              >
                                Nom du panier
                              </label>
                              <Field
                                name={`buckets.${index}.name`}
                                placeholder="Nom du seau"
                                className="mt-2 w-full p-3 border rounded-md"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor={`buckets.${index}.commune`}
                                className="block text-gray-700 font-medium"
                              >
                                Commune du panier
                              </label>
                              <Field
                                name={`buckets.${index}.commune`}
                                placeholder="Commune du panier"
                                className="mt-2 w-full p-3 border rounded-md"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor={`buckets.${index}.namepartenaire`}
                                className="block text-gray-700 font-medium"
                              >
                                Nom du partenaire
                              </label>
                              <Field
                                name={`buckets.${index}.namepartenaire`}
                                placeholder="Nom du partenaire"
                                className="mt-2 w-full p-3 border rounded-md"
                              />
                            </div>

                            <div>
                              <label
                                htmlFor={`buckets.${index}.image`}
                                className="block text-gray-700 font-medium"
                              >
                                Image du panier
                              </label>

                              <ImageSelector
                                currentImage={bucket.image}
                                onImageSelect={(url: any, fieldName: string) =>
                                  setFieldValue(fieldName, url)
                                }
                                fieldName={`buckets.${index}.image`}
                              />

                              {values.buckets[index].image ? (
                                <img
                                  src={values.buckets[index].image}
                                  alt="Bucket preview"
                                  className="mt-2 w-32 h-32 object-cover rounded-md border"
                                />
                              ) : (
                                <div className="mt-2 w-32 h-32 bg-gray-200 flex items-center justify-center rounded-md border-2 border-dashed border-gray-400">
                                  <span className="text-gray-500">
                                    Pas de image
                                  </span>
                                </div>
                              )}
                            </div>
                            <div>
                              <label
                                htmlFor={`buckets.${index}.price`}
                                className="block text-gray-700 font-medium"
                              >
                                Prix du panier
                              </label>
                              <Field
                                name={`buckets.${index}.price`}
                                placeholder="Prix  du seau"
                                className="mt-2 w-full p-3 border rounded-md"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor={`buckets.${index}.prixnormal`}
                                className="block text-gray-700 font-medium"
                              >
                                Prix normal du panier
                              </label>
                              <Field
                                name={`buckets.${index}.prixnormal`}
                                placeholder="Prix normal du seau"
                                className="mt-2 w-full p-3 border rounded-md"
                              />
                            </div>
                          </div>

                          <FieldArray name={`buckets.${index}.products`}>
                            {({ push, remove }) => (
                              <div className="mt-4 space-y-4">
                                <AnimatePresence>
                                  {values.buckets[index].products.map(
                                    (
                                      _: any,
                                      productIndex:
                                        | React.Key
                                        | null
                                        | undefined,
                                      product: { image: any }
                                    ) => (
                                      <motion.div
                                        key={productIndex}
                                        initial={{ opacity: 0, y: -20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -20 }}
                                        transition={{ duration: 0.3 }}
                                        className="p-4 border rounded-lg bg-white"
                                      >
                                        <div className="grid grid-cols-2 gap-4">
                                          <div>
                                            <label
                                              htmlFor={`buckets.${index}.products.${productIndex}.name`}
                                              className="block text-gray-700 font-medium"
                                            >
                                              Nom du produit
                                            </label>
                                            <Field
                                              name={`buckets.${index}.products.${productIndex}.name`}
                                              placeholder="Product Name"
                                              className="mt-2 w-full p-3 border rounded-md"
                                            />
                                          </div>
                                          <div>
                                            <label
                                              htmlFor={`buckets.${index}.products.${productIndex}.image`}
                                              className="block text-gray-700 font-medium"
                                            >
                                              Image du produit
                                            </label>
                                            <ImageSelector
                                              currentImage={product.image}
                                              onImageSelect={(
                                                url: any,
                                                fieldName: string
                                              ) =>
                                                setFieldValue(fieldName, url)
                                              }
                                              fieldName={`buckets.${index}.products.${productIndex}.image`}
                                            />
                                            {values.buckets[index]?.products[
                                              productIndex as number
                                            ]?.image ? (
                                              <img
                                                src={
                                                  values.buckets[index]
                                                    ?.products[
                                                    productIndex as number
                                                  ].image
                                                }
                                                alt="Product preview"
                                                className="mt-2 w-32 h-32 object-cover rounded-md border"
                                              />
                                            ) : (
                                              <div className="mt-2 w-32 h-32 bg-gray-200 flex items-center justify-center rounded-md border-2 border-dashed border-gray-400">
                                                <span className="text-gray-500">
                                                  Pas d'image
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="mt-4 text-right">
                                          <button
                                            type="button"
                                            onClick={() =>
                                              remove(productIndex as number)
                                            }
                                            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-200"
                                          >
                                            <FaTrash className="inline mr-2" />
                                            Supprimer le produit
                                          </button>
                                        </div>
                                      </motion.div>
                                    )
                                  )}
                                </AnimatePresence>
                                <button
                                  type="button"
                                  onClick={() => push({ name: "", image: "" })}
                                  className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-200"
                                >
                                  <FaPlus className="inline mr-2" />
                                  Ajouter un produit
                                </button>
                              </div>
                            )}
                          </FieldArray>

                          <div className="mt-4 text-right">
                            <button
                              type="button"
                              onClick={() => remove(index)}
                              className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-200"
                            >
                              <FaTrash className="inline mr-2" />
                              Supprimer le seau
                            </button>
                          </div>
                        </motion.div>
                      )
                    )}
                  </AnimatePresence>

                  <button
                    type="button"
                    onClick={() =>
                      push({
                        name: "",
                        image: "",
                        price: "",
                        prixnormal: "",
                        products: [],
                      })
                    }
                    className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-200"
                  >
                    <FaPlus className="inline mr-2" />
                    Ajouter un seau
                  </button>
                </div>
              )}
            </FieldArray>

            <div className="text-right">
              <button
                type="submit"
                className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-200"
              >
                {editingCategory ? (
                  <>
                    <FaEdit className="inline mr-2" />
                    Mettre à jour la catégorie
                  </>
                ) : (
                  <>
                    <FaPlus className="inline mr-2" />
                    Créer une catégorie
                  </>
                )}
              </button>
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
}

export default CreationProduitForm;
