import React, { useState, useEffect } from "react";
import { FaPlus, FaTrash, FaEdit, FaImage, FaUpload } from "react-icons/fa";
import { ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../firebase";

interface ImageSelectorProps {
  currentImage: string;
  onImageSelect: (url: string, fieldName: string) => void;
  fieldName: string;
}

export default function ImageSelector({
  currentImage,
  onImageSelect,
  fieldName,
}: ImageSelectorProps) {
  const [uploading, setUploading] = useState(false);
  const [showSelector, setShowSelector] = useState(false);
  const [existingImages, setExistingImages] = useState<string[]>([]);

  useEffect(() => {
    const fetchImages = async () => {
      const imagesRef = ref(storage, "images");
      const imagesList = await listAll(imagesRef);
      const urls = await Promise.all(
        imagesList.items.map((item) => getDownloadURL(item))
      );
      setExistingImages(urls);
    };
    fetchImages();
  }, []);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    if (!file) return;

    setUploading(true);
    const storageRef = ref(storage, `images/${file.name}`);

    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      onImageSelect(downloadURL, fieldName);
      setExistingImages([...existingImages, downloadURL]);
    } catch (error) {
      console.error("Error uploading file: ", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="mt-2">
      {currentImage ? (
        <div className="relative">
          <img
            src={currentImage}
            alt="Selected"
            className="w-full h-32 object-cover rounded-md"
          />
          <button
            type="button"
            onClick={() => setShowSelector(true)}
            className="absolute bottom-2 right-2 bg-white p-2 rounded-full shadow-md"
          >
            <FaEdit />
          </button>
        </div>
      ) : (
        <button
          type="button"
          onClick={() => setShowSelector(true)}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-red-600 transition duration-200"
        >
          <FaUpload className="inline mr-2" /> Ajouter une image
        </button>
      )}

      {showSelector && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg max-w-2xl w-full relative">
            <h3 className="text-lg font-semibold mb-4">
              Sélectionnez une image
            </h3>

            <div className="grid grid-cols-3 gap-4 mb-4 max-h-96 overflow-y-auto">
              {existingImages.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`Existing ${index}`}
                  className="w-full h-24 object-cover rounded-md cursor-pointer hover:opacity-75 transition-opacity"
                  onClick={() => {
                    onImageSelect(url, fieldName);
                    setShowSelector(false);
                  }}
                />
              ))}
            </div>

            <div className="flex justify-between items-center mt-4">
              <label className="cursor-pointer bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors">
                <FaUpload className="inline mr-2" />
                Charger nouveau
                <input
                  type="file"
                  className="hidden"
                  onChange={handleImageUpload}
                  accept="image/*"
                />
              </label>

              <button
                type="button"
                onClick={() => setShowSelector(false)}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition-colors"
              >
                Annuler
              </button>
            </div>

            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={() => setShowSelector(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
