import React, { useState, useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import validator from "validator";
import axios from "axios";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { RegContext } from "../../regContext/RegContext";

/* const { BACKEND_API_URL } = require("../../../../env"); */
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Sect2 = () => {
  const [imageSrc, setImageSrc] = useState("./images/yango_logo.png");

  const getImageUrl = (e: { target: { files: (Blob | MediaSource)[] } }) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
  };

  const [activeTabList, setActiveTabList] = useState(false);
  const [generatedCode, setGeneratedCode] = useState("");
  const [open, setOpen] = useState(false);

  const [currEmail, setCurrEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [codeGen, setCodeGen] = useState(false);
  const [warnOpen, setWarnOpen] = useState(false);
  const [openSendMail, setOpenSendMail] = useState(false);
  const [errorSendMail, setErrorSendMail] = useState(false);
  const { checkValid, setCheckValid } = useContext(RegContext);
  const { prospects, setProspects } = useContext(RegContext);

  const genCode = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let code = "#GP-";

    for (let i = 0; i < 4; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return code;
  };

  const [currCode, setCurrCode] = useState(genCode());

  const handleClickCopy = () => {
    if (generatedCode === "") {
      return;
    }
    navigator.clipboard.writeText(generatedCode);
    setOpen(true);
  };

  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleClose2 = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setEmailError(false);
  };

  const handleClose3 = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setCodeGen(false);
  };

  const handleClose4 = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setWarnOpen(false);
  };

  const handleClose5 = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSendMail(false);
  };

  const handleClose6 = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorSendMail(false);
  };

  const codeSaver = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    // setCurrCode(genCode());

    if (!validator.isEmail(currEmail)) {
      setErrorOpen(true);
      setEmailError(true);
      return;
    }

    setCurrCode(genCode());
    console.log(currCode);

    try {
      console.log(currCode);
      // setCurrCode(genCode());
      const res = await axios.post(`${apiUrl}/api/partCheck`, {
        emailPart: currEmail,
        codePart: currCode,
      });

      console.log(res.data);
      setGeneratedCode(currCode);
      setCodeGen(true);
    } catch (error: any) {
      console.log(error);

      if (error.response.status === 400) {
        setWarnOpen(true);
        return;
      }
    }
  };

  const sendMail = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!validator.isEmail(currEmail)) {
      setErrorOpen(true);
      setEmailError(true);
      return;
    }

    try {
      console.log(generatedCode);
      const res = await axios.post(`${apiUrl}/api/sendMail`, {
        emailPart: currEmail,
        codePart: generatedCode,
      });

      console.log(res.data);
      setOpenSendMail(true);
    } catch (error: any) {
      console.log(error);

      if (error.response.status === 500) {
        setErrorSendMail(true);

        return;
      }
    }
  };

  const handleValidPart = () => {
    setActiveTabList(false);
    setCheckValid(true);
  };

  const handleValidProspect = () => {
    setActiveTabList(true);
    setCheckValid(false);
  };

  return (
    <div className="partenaires_sect2">
      <div className="partenaires_sect2_part_top">
        <div> Giga_plus Partenaires</div>
      </div>

      <div className="partenaires_sect2_part_bottom">
        <div className="partenaires_add_form">
          <div className="partenaires_tab_type">
            <div
              onClick={handleValidPart}
              className={!activeTabList ? "partenaires_tab_type_active" : ""}
            >
              {" "}
              <i className="fa-solid fa-house-circle-check"></i> Liste
              partenaires{" "}
            </div>
            <div
              onClick={handleValidProspect}
              className={activeTabList ? "partenaires_tab_type_active" : ""}
            >
              <i className="fa-solid fa-house-circle-exclamation"></i> Liste
              prospects
              <div className="prospect_number">{prospects.length}</div>
            </div>
          </div>

          <p className="partenaires_gen_creation_div">
            <span>Génerer un #code d'inscription</span>

            <div className="partenaires_form_field">
              <input
                onChange={(e) => setCurrEmail(e.target.value)}
                className="gen_email"
                id="email"
                type="email"
                placeholder="Email partenaire"
                name=""
              />
            </div>

            <button onClick={codeSaver}> Génerer </button>

            <input
              value={generatedCode}
              className="gen_result"
              name=""
              disabled
            />

            <div className="gen_div_button">
              <button onClick={sendMail}>
                {" "}
                <i className="fa-solid fa-paper-plane"></i>&nbsp;Envoyer
              </button>
              <button onClick={handleClickCopy}>
                {" "}
                <i className="fa-solid fa-copy"></i>&nbsp;Copier
              </button>
            </div>

            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={emailError}
                autoHideDuration={2000}
                onClose={handleClose2}
              >
                <Alert
                  onClose={(event: React.SyntheticEvent<Element, Event>) =>
                    handleClose2(event, "")
                  }
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  Email partenaire non valide!
                </Alert>
              </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={codeGen}
                autoHideDuration={1000}
                onClose={handleClose3}
              >
                <Alert
                  onClose={(event: React.SyntheticEvent<Element, Event>) =>
                    handleClose3(event, "")
                  }
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Code généré avec succès!
                </Alert>
              </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={warnOpen}
                autoHideDuration={2000}
                onClose={handleClose4}
              >
                <Alert
                  onClose={(event: React.SyntheticEvent<Element, Event>) =>
                    handleClose4(event, "")
                  }
                  severity="warning"
                  sx={{ width: "100%" }}
                >
                  Un code a déjà été généré pour cet email !
                </Alert>
              </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={open}
                autoHideDuration={1000}
                onClose={handleClose}
              >
                <Alert
                  onClose={(event: React.SyntheticEvent<Element, Event>) =>
                    handleClose(event, "")
                  }
                  severity="info"
                  sx={{ width: "100%" }}
                >
                  Code copié!
                </Alert>
              </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={openSendMail}
                autoHideDuration={1000}
                onClose={handleClose5}
              >
                <Alert
                  onClose={(event: React.SyntheticEvent<Element, Event>) =>
                    handleClose5(event, "")
                  }
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Le code a été envoyé par email au partenaire avec succès!
                </Alert>
              </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={errorSendMail}
                autoHideDuration={1000}
                onClose={handleClose6}
              >
                <Alert
                  onClose={(event: React.SyntheticEvent<Element, Event>) =>
                    handleClose6(event, "")
                  }
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  Echec! l'email n'a pas pu être envoyé.
                </Alert>
              </Snackbar>
            </Stack>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sect2;
