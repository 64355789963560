import React, { useEffect, useState } from "react";
import axios from "axios";
/* const { BACKEND_API_URL } = require("../../../../env"); */
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
interface InfoItem {
  _id: string;
  idUsager: {
    datenaissance:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | null
      | undefined;
    nom: string;
    tel: string;
  };
  nbreDonneeCreditee: number;
  idVideo: { intitulee: string };
  idOperateur: { nomOperateur: string };
}
const Details = (props: {
  onClose: React.MouseEventHandler<HTMLDivElement> | undefined;
  idUsager:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
  nom:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
  datenaissance:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
  tel:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
  video:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
  gigaDonnée:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
  operateur:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
}) => {
  return (
    <div className="sect3_elem_details">
      <div>
        <div onClick={props.onClose} className="elem_details_closure">
          {" "}
          <i className="fa-solid fa-xmark"></i>{" "}
        </div>

        <div className="part_details_div">
          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Gestion des points </b>{" "}
              </span>
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> ID : &nbsp; </span> <span> {props.idUsager} </span>{" "}
              </div>
              <div>
                <span> Nom Utilisateur : &nbsp; </span>{" "}
                <span>
                  {" "}
                  {props.nom} &nbsp; <b>S</b>{" "}
                </span>
              </div>
              <div>
                <span> Numero Telephone : &nbsp; </span>{" "}
                <span> {props.tel} </span>{" "}
              </div>
              <div>
                <span> Nom Video : &nbsp; </span> <span> {props.video} </span>{" "}
              </div>
              <div>
                <span> Nombre De Giga Donnée : &nbsp; </span>{" "}
                <span> {props.gigaDonnée} </span>{" "}
              </div>
              <div>
                <span> Operateur : &nbsp; </span>{" "}
                <span> {props.operateur} </span>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Bilan = (props: {
  color: string;
  count:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
  title:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
}) => {
  return (
    <div className="sect1_bilan">
      <div style={{ borderColor: props.color }}>{props.count}</div>
      <span>{props.title}</span>
    </div>
  );
};
const Sect1 = () => {
  const [nomOperateur, setNomOperateur] = useState([]);
  const [displayItem, setDisplayItem] = useState(false);
  const [info, setInfo] = useState<InfoItem[]>([]);
  const [user, setUser] = useState<InfoItem | null>(null);

  const [total, setTotal] = useState(0);
  const [itemsPerPage2, setItemsPerPage2] = useState(6);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [currentPage2, setCurrentPage2] = useState(1);
  const [totalPages2, setTotalPages2] = useState(0);
  const [hommesSandwich, setHommesSandwich] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [visionnages, setVisionnages] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const closeChild = () => {
    setDisplayItem(false);
  };
  useEffect(() => {
    setLoading(true);
    const limit1 = itemsPerPage2;
    const page = currentPage2;
    axios
      .get(`${apiUrl}/api/visionnages?limit=${limit1}&page=${page}`)
      .then((response) => {
        setVisionnages(response.data.viewings);
        setTotalPages2(response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleUser = (info: InfoItem) => {
    setUser(info);
    setDisplayItem(true);
  };

  return (
    <div className="partenaires_sect1">
      <div className="partenaires_sect1_top">
        <div className="donnees_sect1_bilan_left"> Bilan parrainages </div>

        <Bilan title="Total parrainages" count={0} color="" />
        <Bilan title="Usagers parrainés" count={0} color="" />
        <Bilan title="Usagers non parrainés" count={0} color="" />
      </div>

      <div className="partenaires_sect1_bottom">
        <div className="partenaires_sect1_bottom_sect_topPoints">
          <div>Liste des utilisateur parrainés</div>
          <div></div>
        </div>

        <div
          className="partenaires_sect1_bottom_sect_table"
          style={{ overflowY: "scroll", height: "300px" }}
        >
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nom</th>
                <th>Numéro</th>
                <th>Qté</th>
                <th>Vid</th>
                <th>Opérateur</th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {info.map((info) => (
                <tr key={info._id}>
                  <td>{info._id}</td>
                  <td>{info.idUsager.nom}</td>
                  <td>{info.idUsager.tel}</td>
                  <td>{info.nbreDonneeCreditee}</td>
                  <td>{info.idVideo.intitulee}</td>
                  <td>{info.idOperateur.nomOperateur}</td>

                  <td>
                    {" "}
                    <i
                      onClick={() => handleUser(info)}
                      className="fa-solid fa-tv"
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {displayItem && user && (
            <Details
              idUsager={user._id}
              nom={user.idUsager.nom}
              video={user.idVideo.intitulee}
              tel={user.idUsager.tel}
              gigaDonnée={user.nbreDonneeCreditee}
              datenaissance={user.idUsager.datenaissance}
              operateur={user.idOperateur.nomOperateur}
              onClose={closeChild}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Sect1;
