import React, { useState, useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import validator from "validator";
import axios from "axios";
import { RegContext } from "../../regContext/RegContext";
/* const { BACKEND_API_URL } = require("../../../../env"); */
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

const Sect2 = () => {
  const [imageSrc, setImageSrc] = useState("./images/yango_logo.png");

  const getImageUrl = (e: { target: { files: (Blob | MediaSource)[] } }) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
  };

  const [activeTabList, setActiveTabList] = useState(false);
  const [generatedCode, setGeneratedCode] = useState("");
  const [open, setOpen] = useState(false);

  const [currEmail, setCurrEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [codeGen, setCodeGen] = useState(false);
  const [warnOpen, setWarnOpen] = useState(false);
  const [openSendMail, setOpenSendMail] = useState(false);
  const [errorSendMail, setErrorSendMail] = useState(false);
  const { checkValid, setCheckValid } = useContext(RegContext);
  const { prospects, setProspects } = useContext(RegContext);

  const genCode = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let code = "#GP-";

    for (let i = 0; i < 4; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return code;
  };

  const [currCode, setCurrCode] = useState(genCode());

  const handleClickCopy = () => {
    if (generatedCode === "") {
      return;
    }
    navigator.clipboard.writeText(generatedCode);
    setOpen(true);
  };

  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleClose2 = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setEmailError(false);
  };

  const handleClose3 = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setCodeGen(false);
  };

  const handleClose4 = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setWarnOpen(false);
  };

  const handleClose5 = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSendMail(false);
  };

  const handleClose6 = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorSendMail(false);
  };

  const codeSaver = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    // setCurrCode(genCode());

    if (!validator.isEmail(currEmail)) {
      setErrorOpen(true);
      setEmailError(true);
      return;
    }

    setCurrCode(genCode());
    console.log(currCode);

    try {
      console.log(currCode);
      // setCurrCode(genCode());
      const res = await axios.post(`${apiUrl}/api/partCheck`, {
        emailPart: currEmail,
        codePart: currCode,
      });

      console.log(res.data);
      setGeneratedCode(currCode);
      setCodeGen(true);
    } catch (error: any) {
      console.log(error);

      if (error.response.status === 400) {
        setWarnOpen(true);
        // setEmailError(true);
        return;
      }
    }
  };

  const sendMail = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!validator.isEmail(currEmail)) {
      setErrorOpen(true);
      setEmailError(true);
      return;
    }

    try {
      console.log(generatedCode);
      const res = await axios.post(`${apiUrl}/api/sendMail`, {
        emailPart: currEmail,
        codePart: generatedCode,
      });

      console.log(res.data);
      setOpenSendMail(true);
    } catch (error: any) {
      console.log(error);

      if (error.response.status === 500) {
        setErrorSendMail(true);
        // setEmailError(true);
        return;
      }
    }
  };

  const handleValidPart = () => {
    setActiveTabList(false);
    setCheckValid(true);
  };

  const handleValidProspect = () => {
    setActiveTabList(true);
    setCheckValid(false);
  };

  return <div className="partenaires_sect2"></div>;
};

export default Sect2;
