import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/* const { BACKEND_API_URL } = require("../../../../env");
const BACKEND_TEST_URL = "https://giga-plus-dev-ec172b1d0071.herokuapp.com"; */
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
interface DetailsProps {
  idVis: string;
  intitulee: string;
  duree: string;
  nbDatas: number;
  packVideo: string;
  gigaRestant?: number;
  nomPartenaire: string;
  dateajout?: string;
  statut: string;
  prixCampagneVideo?: number;
  urlvideo: string;
  achatUrl: string;
  onClose: () => void;
}

interface Visionnage {
  _id: string;
  intitulee: string;
  duree: string;
  nbDatas?: number;
  partenaire?: {
    nomPartenaire: string;
    visionnages?: any[];
  };
  url: string;
  packVideoCopy?: {
    nom: string;
  };
  gigaRestant?: number;
  dateAjout?: string;
  statutVideo?: boolean;
  prixCampagneVideo?: number;
  playCount: number;
  achatUrl: string;
  learnMoreClicks: number;
}
const Sect1 = ({ uploadComplete }: { uploadComplete: boolean }) => {
  const [displayItem, setDisplayItem] = useState(false);
  const [visionnages, setVisionnages] = useState<Visionnage[]>([]);
  const [loading, setLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currVisionnage, setCurrVisionnage] = useState<Visionnage | null>(null);
  const [playCountsData, setPlayCountsData] = useState([]);

  useEffect(() => {
    const fetchPlayCounts = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/visionnage/playcounts`);
        /*   console.log("Play counts data:", response.data.playcount); */
        setPlayCountsData(response.data);
      } catch (error) {
        console.error("Failed to fetch play counts:", error);
      }
    };

    fetchPlayCounts();
  }, []);

  console.log("le changemnent", uploadComplete);
  const closeChild = () => {
    setDisplayItem(false);
  };

  const handleVisionnage = (visionnage: any) => {
    setCurrVisionnage(visionnage);
    setDisplayItem(true);
  };

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    fetchVisionnages(searchTerm, pageNumber);
  };

  const searchHandler = () => {
    fetchVisionnages(searchTerm, 1);
  };

  const fetchVisionnages = (searchTerm: string, page: number) => {
    setLoading(true);
    axios
      .get(`${apiUrl}/api/video/getVideos`, {
        params: {
          limit: itemsPerPage,
          page: page,
          search: searchTerm,
        },
      })
      .then((response) => {
        console.log("Fetched videos data:", response.data.videos);
        setVisionnages(response.data.videos);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchVisionnages(searchTerm, currentPage);
  }, [searchTerm, currentPage, uploadComplete]);

  return (
    <div className="visionnage_sect1_bottom">
      <div className="partenaires_sect1_bottom">
        <div
          className="partenaires_sect1_bottom_sect_top"
          style={{ height: 50 }}
        >
          <div>Liste Video</div>
          <div className="search-bar-container2">
            <input
              type="text"
              placeholder="Rechercher par intitule video"
              className="search-bar-hs2"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              onClick={searchHandler}
              disabled={loading || searchTerm.length === 0}
              className={
                loading || searchTerm.length === 0
                  ? "search-button-disabled2"
                  : "search-button2"
              }
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>

        <div
          className="partenaires_sect1_bottom_sect_table"
          style={{ overflowY: "scroll", height: "350px" }}
        >
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Intitule video</th>
                <th>Duree Video</th>
                <th>Prix par vue</th>
                <th>Nom partenaire</th>
                <th>Nombre de vue</th>
                <th>Nombre de click</th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <ScaleLoader
                  style={{
                    position: "absolute",
                    top: "20vh",
                    left: "48%",
                  }}
                  color="#4EAC04"
                />
              )}
              {!loading &&
                visionnages.map((visionnage) => (
                  <tr key={visionnage._id}>
                    <td>{visionnage._id}</td>
                    <td>{visionnage.intitulee}</td>
                    <td>{visionnage.duree}</td>
                    <td>{visionnage.nbDatas ? visionnage.nbDatas : 25}</td>
                    <td>
                      {visionnage.partenaire ? (
                        visionnage.partenaire.nomPartenaire
                      ) : (
                        <span></span>
                      )}
                    </td>
                    <td>{visionnage.playCount ? visionnage.playCount : 0}</td>
                    <td>
                      {visionnage.learnMoreClicks
                        ? visionnage.learnMoreClicks
                        : 0}
                    </td>
                    <td>
                      <i
                        onClick={() => handleVisionnage(visionnage)}
                        className="fa-solid fa-tv"
                      ></i>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          <div className="pagination-hs">
            <button
              className="pagination-button"
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <i className="fa fa-angle-left"></i>
            </button>
            <span className="current-page">
              {currentPage}/{totalPages}
            </span>
            <button
              className="pagination-button"
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <i className="fa fa-angle-right"></i>
            </button>
          </div>
          {displayItem && currVisionnage && (
            <Details
              idVis={currVisionnage._id}
              intitulee={currVisionnage.intitulee}
              duree={currVisionnage.duree}
              nbDatas={currVisionnage.nbDatas ? currVisionnage.nbDatas : 25}
              packVideo={currVisionnage.packVideoCopy?.nom ?? ""}
              gigaRestant={currVisionnage.gigaRestant}
              nomPartenaire={currVisionnage.partenaire?.nomPartenaire ?? "NULL"}
              dateajout={currVisionnage.dateAjout}
              statut={currVisionnage.statutVideo ? "Activer" : "Desactiver"}
              prixCampagneVideo={currVisionnage.prixCampagneVideo}
              urlvideo={currVisionnage.url}
              achatUrl={currVisionnage.achatUrl}
              onClose={closeChild}
            />
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

const Details = (props: DetailsProps) => {
  const [show, setShow] = useState(false);
  const [lesnbDatas, setLesNbDatas] = useState(props.nbDatas);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [achatUrl, setAchatUrl] = useState(props.achatUrl);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const deleteVideo = () => {
    axios
      .delete(`${apiUrl}/api/video/deleteVideo/${props.idVis}`)
      .then((res) => {
        console.log(res);
        setShow(true);
        window.location.reload();
      })
      .catch((e) => console.log(e));
  };
  const date = new Date(props.dateajout || "");

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };
  const formattedDate = `${date.toLocaleDateString("fr-FR", options as any)}`;

  const handleUpdate = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.put(
        `${apiUrl}/api/video/updateVideo/${props.idVis}`,
        {
          nbDatas: lesnbDatas,
          achatUrl: achatUrl,
        }
      );

      if (response.status === 200) {
        toast.success("Vidéo mise à jour avec succès.");
        window.location.reload();
      }
    } catch (error: any) {
      console.error(
        "Error updating video:",
        error.response ? error.response.data : error.message
      );
      toast.error("Erreur lors de la mise à jour du vidéo.");
    } finally {
      setLoading(false);
    }
  };

  const blockVideo = (id: string) => {
    axios
      .put(`${apiUrl}/api/video/video/${id}/block`)
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((e) => console.log(e));
    console.log("ok block");
  };
  const unBlockVideo = (id: string) => {
    axios
      .put(`${apiUrl}/api/video/video/${id}/unblock`)
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((e) => console.log(e));
    console.log("ok unblock");
  };
  const handleBlockClick = (id: string) => {
    blockVideo(id);
  };
  const handleUnBlockClick = (id: string) => {
    unBlockVideo(id);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="sect3_elem_details">
      <div>
        <div onClick={props.onClose} className="elem_details_closure">
          {" "}
          <i className="fa-solid fa-xmark"></i>{" "}
        </div>
        <div className="part_details_div">
          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails Video </b>{" "}
              </span>
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> ID : &nbsp; </span> <span> {props.idVis} </span>{" "}
              </div>

              <div>
                <span> Intitule Video : &nbsp; </span>{" "}
                <span> {props.intitulee} </span>{" "}
              </div>

              <div>
                <span> Duree Video : &nbsp; </span> <span> {props.duree} </span>{" "}
              </div>

              {/*  <div>
                <span> Prix par vue : &nbsp; </span>
              </div> */}
              {/*  <div>
               
              </div> */}
              <div>
                <span className="font-bold">url d'acahat</span>
                <input
                  value={achatUrl}
                  onChange={(e) => setAchatUrl(e.target.value)}
                  className="border rounded px-2 py-1"
                />
              </div>
              {/*  <div>
               
              </div> */}
              <div>
                <span className="font-bold">prix par vue</span>
                <input
                  type="number"
                  value={lesnbDatas}
                  onChange={(e) => setLesNbDatas(Number(e.target.value))}
                  className="border rounded px-2 py-1"
                />
              </div>
            </div>
          </div>

          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails Video </b>{" "}
              </span>
            </div>

            <div className="row1_details_details">
              <br />
              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> Nom Partenaire : &nbsp; </span>{" "}
                <span> {props.nomPartenaire} </span>{" "}
              </div>

              <div className="row_status_div">
                <span> Date Ajout: &nbsp; </span>
                <span> {formattedDate} </span>{" "}
              </div>
              <div className="row_status_div">
                <span> Montant Pour Video: &nbsp; </span>
                <span> {props.prixCampagneVideo} </span>{" "}
              </div>
              <div className="row_status_div">
                <span> Statu video: &nbsp; </span>
                <span> {props.statut} </span>{" "}
              </div>
              <div className="row_status_div">
                <span>Choisir le statut:</span>
                <button
                  style={{
                    width: "80px",
                    height: "25px",
                    borderRadius: 7,
                    border: "none",
                    backgroundColor: "red",
                    fontWeight: "bold",
                    color: "#ffff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleBlockClick(props.idVis)}
                >
                  Bloquer
                </button>
                <button
                  style={{
                    width: "90px",
                    height: "25px",
                    borderRadius: 7,
                    border: "none",
                    backgroundColor: "green",
                    fontWeight: "bold",
                    color: "#ffff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleUnBlockClick(props.idVis)}
                >
                  Debloquer
                </button>
              </div>
            </div>
            {/*   <div className="">
              <button
                style={{
                  marginTop: 400,
                  height: 30,
                  width: 100,
                  borderRadius: 7,
                  border: "none",
                  fontWeight: "bold",
                  color: "#ffff",
                  backgroundColor: "red",
                  cursor: "pointer",
                }}
                onClick={() => deleteVideo()}
              >
                Supprimer
              </button>
              <button
                style={{
                  marginTop: 400,
                  height: 30,
                  width: 100,
                  borderRadius: 7,
                  border: "none",
                  fontWeight: "green",
                  color: "#ffff",
                  backgroundColor: "red",
                  cursor: "pointer",
                }}
                onClick={updateNbDatas}
                disabled={loading}
              >
                {loading ? "Updating..." : "mettre a jour"}
              </button>
            </div> */}
          </div>
        </div>
        {/*  <div className="flex space-x-4 mt-8 align-center w-full">
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => deleteVideo()}
          >
            Supprimer
          </button>
          <button
            className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={updateNbDatas}
            disabled={loading}
          >
            {loading ? "Updating..." : "Mettre à jour"}
          </button>
        </div> */}
        {/* <div>
          <div className="flex justify-center space-x-4 mt-8">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => deleteVideo()}
            >
              Supprimer
            </button>
            <button
              className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={handleUpdate}
              disabled={loading}
            >
              {loading ? "Mise à jour..." : "Mettre à jour"}
            </button>
          </div>
          <div>
            <video src={props.urlvideo} controls></video>
          </div>
        </div> */}
        <div className="flex justify-center items-center w-full mt-2 p-8">
          <div className="flex space-x-4">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => deleteVideo()}
            >
              Supprimer
            </button>
            <button
              className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={handleUpdate}
              disabled={loading}
            >
              {loading ? "Mise à jour..." : "Mettre à jour"}
            </button>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleButtonClick}
            >
              Regardez la vidéo
            </button>
            {isModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-white p-4 rounded-lg w-11/12 md:w-2/3 lg:w-1/2">
                  <button
                    className="text-black float-right font-bold text-lg"
                    onClick={handleCloseModal}
                  >
                    &times;
                  </button>
                  <video
                    src={props.urlvideo}
                    controls
                    autoPlay
                    className="w-full h-auto mt-4 max-h-[80vh]"
                  ></video>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sect1;
