import React from "react";
import { FaPlus, FaTrash, FaEdit } from "react-icons/fa";
import { Formik, FieldArray, Form as FormikForm, Field } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import ImageSelector from "./ImageSelector";
import { toast } from "react-toastify";
interface Category {
  _id: string;
  name: string;
  color: string;
  namepartenaire: string;
  buckets: any[]; // You might want to define a more specific type for buckets
}

interface EditingCategorieProps {
  handleUpdateCategory: (values: any, id: string) => void;
  handleCreateCategory: (values: any) => void;
  editingCategory: Category | null;
  setEditingCategory: React.Dispatch<React.SetStateAction<Category | null>>;
  deletecategory: (id: string) => void;
}
function EditingCategorie({
  handleUpdateCategory,
  handleCreateCategory,
  editingCategory,
  setEditingCategory,
  deletecategory,
}: EditingCategorieProps) {
  const Button = styled(motion.button)`
    background: #4299e1;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1rem;
    font-size: 1rem;

    &:hover {
      background: #3182ce;
    }
  `;
  return (
    <div className="p-6 bg-white rounded-lg shadow-lg max-w-4xl mx-auto">
      <div className="flex justify-start">
        <Button
          type="button"
          onClick={() => {
            if (editingCategory) {
              deletecategory(editingCategory._id);
            } else {
              toast.error("Aucune catégorie sélectionnée");
            }
          }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          aria-label="Create new category"
          style={{
            marginTop: "15px",
            height: "30px",
            background: "#f44336",
            color: "white",
            marginLeft: "10px",
          }}
        >
          <FaTrash />
          Supprimer cette catégorie
        </Button>
      </div>
      <Formik
        initialValues={{
          name: editingCategory?.name || "",
          color: editingCategory?.color || "",
          buckets: editingCategory?.buckets || [
            {
              name: "",
              image: "",
              price: "",
              namepartenaire: "",
              prixnormal: "",
              commune: "",
              products: [{ name: "", image: "" }],
            },
          ],
        }}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          if (editingCategory) {
            handleUpdateCategory(values, editingCategory._id);
          } else {
            handleCreateCategory(values);
          }
          resetForm();
          setEditingCategory(null);
        }}
      >
        {({ values, setFieldValue }) => (
          <FormikForm className="space-y-6 mt-10">
            <div>
              <label htmlFor="name" className="block text-gray-700 font-medium">
                Nom de la catégorie
              </label>
              <Field
                name="name"
                placeholder="Nom de la catégorie"
                className="mt-2 w-full p-3 border rounded-md"
              />
            </div>
            {/* <div>
              <label htmlFor="name" className="block text-gray-700 font-medium">
                Nom du partenaire
              </label>
              <Field
                name="namepartenaire"
                placeholder="Nom du partenaire"
                className="mt-2 w-full p-3 border rounded-md"
              />
            </div> */}
            <FieldArray name="buckets">
              {({ push, remove }) => (
                <div className="space-y-6">
                  <AnimatePresence>
                    {values.buckets.map((_, index, bucket) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                        className="p-4 border rounded-lg bg-gray-50"
                      >
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <label
                              htmlFor={`buckets.${index}.name`}
                              className="block text-gray-700 font-medium"
                            >
                              Nom du seau
                            </label>
                            <Field
                              name={`buckets.${index}.name`}
                              placeholder="Nom du seau"
                              className="mt-2 w-full p-3 border rounded-md"
                            />
                          </div>
                          <div>
                            <label
                              htmlFor={`buckets.${index}.commune`}
                              className="block text-gray-700 font-medium"
                            >
                              Commune du panier
                            </label>
                            <Field
                              name={`buckets.${index}.commune`}
                              placeholder="commune du panier"
                              className="mt-2 w-full p-3 border rounded-md"
                            />
                          </div>
                          <div>
                            <label
                              htmlFor={`buckets.${index}.namepartenaire`}
                              className="block text-gray-700 font-medium"
                            >
                              Nom du partenaire
                            </label>
                            <Field
                              name={`buckets.${index}.namepartenaire`}
                              placeholder="Nom du partenaire"
                              className="mt-2 w-full p-3 border rounded-md"
                            />
                          </div>
                          <div>
                            <label
                              htmlFor={`buckets.${index}.image`}
                              className="block text-gray-700 font-medium"
                            >
                              URL de l'image du seau
                            </label>
                            <ImageSelector
                              currentImage={values.buckets[index].image}
                              onImageSelect={(url: string, fieldName: string) =>
                                setFieldValue(fieldName, url)
                              }
                              fieldName={`buckets.${index}.image`}
                            />

                            {values.buckets[index].image ? (
                              <img
                                src={values.buckets[index].image}
                                alt="Bucket preview"
                                className="mt-2 w-32 h-32 object-cover rounded-md border"
                              />
                            ) : (
                              <div className="mt-2 w-32 h-32 bg-gray-200 flex items-center justify-center rounded-md border-2 border-dashed border-gray-400">
                                <span className="text-gray-500">
                                  Pas de image
                                </span>
                              </div>
                            )}
                          </div>
                          <div>
                            <label
                              htmlFor={`buckets.${index}.price`}
                              className="block text-gray-700 font-medium"
                            >
                              Prix du seau
                            </label>
                            <Field
                              name={`buckets.${index}.price`}
                              placeholder="Prix  du seau"
                              className="mt-2 w-full p-3 border rounded-md"
                            />
                          </div>
                          <div>
                            <label
                              htmlFor={`buckets.${index}.prixnormal`}
                              className="block text-gray-700 font-medium"
                            >
                              Prix normal du seau
                            </label>
                            <Field
                              name={`buckets.${index}.prixnormal`}
                              placeholder="Prix normal du seau"
                              className="mt-2 w-full p-3 border rounded-md"
                            />
                          </div>
                        </div>

                        <FieldArray name={`buckets.${index}.products`}>
                          {({ push, remove }) => (
                            <div className="mt-4 space-y-4">
                              <AnimatePresence>
                                {values.buckets[index].products.map(
                                  (
                                    _: any,
                                    productIndex: number,
                                    product: any
                                  ) => (
                                    <motion.div
                                      key={productIndex}
                                      initial={{ opacity: 0, y: -20 }}
                                      animate={{ opacity: 1, y: 0 }}
                                      exit={{ opacity: 0, y: -20 }}
                                      transition={{ duration: 0.3 }}
                                      className="p-4 border rounded-lg bg-white"
                                    >
                                      <div className="grid grid-cols-2 gap-4">
                                        <div>
                                          <label
                                            htmlFor={`buckets.${index}.products.${productIndex}.name`}
                                            className="block text-gray-700 font-medium"
                                          >
                                            Nom du produit
                                          </label>
                                          <Field
                                            name={`buckets.${index}.products.${productIndex}.name`}
                                            placeholder="Product Name"
                                            className="mt-2 w-full p-3 border rounded-md"
                                          />
                                        </div>
                                        <div>
                                          <label
                                            htmlFor={`buckets.${index}.products.${productIndex}.image`}
                                            className="block text-gray-700 font-medium"
                                          >
                                            Image du produit
                                          </label>
                                          <ImageSelector
                                            currentImage={product.image}
                                            onImageSelect={(
                                              url: string,
                                              fieldName: string
                                            ) => setFieldValue(fieldName, url)}
                                            fieldName={`buckets.${index}.products.${productIndex}.image`}
                                          />

                                          {values.buckets[index].products[
                                            productIndex
                                          ].image ? (
                                            <img
                                              src={
                                                values.buckets[index].products[
                                                  productIndex
                                                ].image
                                              }
                                              alt="Product preview"
                                              className="mt-2 w-32 h-32 object-cover rounded-md border"
                                            />
                                          ) : (
                                            <div className="mt-2 w-32 h-32 bg-gray-200 flex items-center justify-center rounded-md border-2 border-dashed border-gray-400">
                                              <span className="text-gray-500">
                                                Pas d'image
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="mt-4 text-right">
                                        <button
                                          type="button"
                                          onClick={() => remove(productIndex)}
                                          className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-200"
                                        >
                                          <FaTrash className="inline mr-2" />
                                          Supprimer le produit
                                        </button>
                                      </div>
                                    </motion.div>
                                  )
                                )}
                              </AnimatePresence>
                              <button
                                type="button"
                                onClick={() => push({ name: "", image: "" })}
                                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-200"
                              >
                                <FaPlus className="inline mr-2" />
                                Ajouter un produit
                              </button>
                            </div>
                          )}
                        </FieldArray>

                        <div className="mt-4 text-right">
                          <button
                            type="button"
                            onClick={() => remove(index)}
                            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-200"
                          >
                            <FaTrash className="inline mr-2" />
                            Supprimer le seau
                          </button>
                        </div>
                      </motion.div>
                    ))}
                  </AnimatePresence>

                  <button
                    type="button"
                    onClick={() =>
                      push({
                        name: "",
                        image: "",
                        price: "",
                        prixnormal: "",
                        products: [],
                      })
                    }
                    className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-200"
                  >
                    <FaPlus className="inline mr-2" />
                    Ajouter un seau
                  </button>
                </div>
              )}
            </FieldArray>

            <div className="text-right">
              <button
                type="submit"
                className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-200"
              >
                {editingCategory ? (
                  <>
                    <FaEdit className="inline mr-2" />
                    Mettre à jour la catégorie
                  </>
                ) : (
                  <>
                    <FaPlus className="inline mr-2" />
                    Créer une catégorie
                  </>
                )}
              </button>
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
}

export default EditingCategorie;
