import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const [cookies] = useCookies(["giga_admin"]);

  const hasAdmin = cookies.giga_admin !== undefined;

  const isTokenValid = () => {
    if (!hasAdmin) return false;

    const payload = cookies.giga_admin.accessToken.split(".")[1];
    const payloadData = JSON.parse(atob(payload));

    const currentTime = new Date().getTime() / 1000;
    return currentTime < payloadData.exp;
  };

  return isTokenValid() ? children : <Navigate to="/" />;
};

export default PrivateRoute;
