import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Field } from "formik";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FaPlus, FaTrash, FaEdit, FaEye } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GestionHeader from "../../composants/GestionHeader";
import { ScaleLoader } from "react-spinners";
import Tooltip from "@mui/material/Tooltip";
import CreationProduitForm from "../../composants/CreationProduitForm";
import Modal from "../../composants/Modal";
import EditingCategorie from "../../composants/EditingCategorie";
import CategoryProducts from "../../composants/CategoryProducts";
import LoginBonusAdmin from "../../composants/LoginBonusAdmin";

interface Category {
  _id: string;
  name: string;
  buckets: Array<{ products: any[] }>;
}

const Details = (props: any) => {
  return (
    <div className="sect3_elem_details">
      <div>
        <div onClick={props.onClose} className="elem_details_closure">
          {" "}
          <i className="fa-solid fa-xmark"></i>{" "}
        </div>

        {/*   {props.allcategories.map((category) => ( */}
        <div className="flex flex-row items-center space-x-2">
          <Button
            type="button"
            /*   onClick={props.editingCategory} */
            onClick={props.handleEditClick}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            aria-label="Create new category"
            style={{
              marginTop: "15px",
              height: "30px",
              background: "#4CAF50",
              color: "white",
              marginLeft: "10px",
            }}
          >
            <FaEdit />
            Modifier catégorie
          </Button>
          <Button
            type="button"
            onClick={props.deletecategory}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            aria-label="Create new category"
            style={{
              marginTop: "15px",
              height: "30px",
              background: "#f44336",
              color: "white",
              marginLeft: "10px",
            }}
          >
            <FaTrash />
            Supprimer catégorie
          </Button>
        </div>
        {/*   ))} */}
        {/*  {props.maincategories.map((maincategory, index) => ( */}
        <div className="part_details_div">
          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b>Détails de la catégorie</b>{" "}
              </span>
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> ID : &nbsp; </span> <span> {props.idVis} </span>{" "}
              </div>
              <div>
                <span> Nom de la catégorie : &nbsp; </span>{" "}
                <span>
                  {" "}
                  {props.dureeVidVis} &nbsp; <b>S</b>{" "}
                </span>
              </div>
              <div>
                <span> Nombres de Paniers : &nbsp; </span>{" "}
                <span> {props.gigaVis}</span>{" "}
              </div>
              <div>
                <span> Nombres de produits : &nbsp; </span>{" "}
                <span> {props.opeVis} </span>{" "}
              </div>
            </div>
          </div>

          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Paniers et détails </b>{" "}
              </span>
              {/* <span> {props.partDescription} </span> */}
            </div>
            {/*  <ExpendableList
              categories={props.buckets}
               handleUpdate={props.handleUpdate}
              handleDelete={props.handleDelete}  
            /> */}
          </div>
        </div>
        {/*  ))} */}
      </div>
    </div>
  );
};

/* const BACKEND_TEST_URL = "https://giga-plus-dev-ec172b1d0071.herokuapp.com"; */
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: "Roboto", sans-serif;
`;

const Title = styled.h1`
  color: #2d3748;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: bold;
`;

const StyledForm = styled(Form)`
  background: #edf2f7;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
`;

const Label = styled.label`
  font-size: 1rem;
  color: #4a5568;
  margin-bottom: 0.5rem;
  display: block;
`;

const Input = styled(Field)`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #cbd5e0;
  border-radius: 6px;
  background: #f7fafc;
`;

const Button = styled(motion.button)`
  background: #4299e1;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
  font-size: 1rem;

  &:hover {
    background: #3182ce;
  }
`;

const ErrorMessage = styled.div`
  color: #e53e3e;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
`;

const CategoryList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const CategoryItem = styled(motion.li)`
  background: #ffffff;
  margin-bottom: 1rem;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-4px);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 0.5rem;
`;

interface Category {
  _id: string;
  name: string;
  buckets: Array<{ products: any[] }>;
  color: string;
}

const Gestionnairepanier = () => {
  const [displayItem, setDisplayItem] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currVisionnage, setCurrVisionnage] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [editingCategory, setEditingCategory] = useState<Category | null>(null);

  const handleEditClick = (category: Category) => {
    setEditingCategory(category);
    setIsEditing(true);
    setDisplayItem(false);
    openEditingModal();
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openEditingModal = () => setIsEditModalOpen(true);
  const closEditingeModal = () => setIsEditModalOpen(false);

  useEffect(() => {
    if (!editingCategory) {
      setEditingCategory(null);
    }
  }, [editingCategory]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/categories`);
      console.log("Categories fetched:", response.data);
      setCategories(response.data);
      setLoading(false);
    } catch (error: any) {
      console.error(
        "Error fetching categories:",
        error.response || error.message || error
      );
      toast.error("Erreur lors de la récupération des catégories");
    }
  };

  const handleCreateCategory = async (values: any) => {
    console.log("Creating new category:", values);
    try {
      const response = await axios.post(
        `${apiUrl}/api/categories`,
        values
      );
      if (response.status === 201) {
        console.log("Category created:", response.data);
        fetchCategories();
        toast.success("Catégorie créée avec succès.");
      } else {
        toast.error("Statut de réponse inattendu.");
      }
    } catch (error: any) {
      console.error(
        "Error creating category:",
        error.response?.data || error.message || error
      );
      toast.error("Erreur lors de la création de la catégorie");
    }
  };

  const handleDeleteCategory = async (id: any) => {
    console.log("Deleting category with id:", id);
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette catégorie?")) {
      try {
        const response = await axios.delete(
          `${apiUrl}/api/categories/${id}`
        );
        console.log("Category deleted:", response.data);
        fetchCategories();
        toast.success("Catégorie supprimée avec succès.");
      } catch (error) {
        toast.error("Erreur lors de la suppression de la catégorie");
      }
    }
    closEditingeModal();
  };
  const handleVisionnage = (category: any) => {
    setCurrVisionnage(category);
    console.log("the current category details", category);
    setDisplayItem(true);
  };

  const handleUpdateCategory = async (values: any) => {
    try {
      if (!editingCategory?._id) {
        throw new Error("Category ID is missing");
      }
      const response = await axios.put(
        `${apiUrl}/api/categories/${editingCategory?._id}`,
        values
      );
      if (response.status === 200) {
        console.log("Category updated:", response.data);
        fetchCategories();
        toast.success("Catégorie mise à jour avec succès.");
      } else {
        toast.error("Statut de réponse inattendu.");
      }
    } catch (error: any) {
      console.error(
        "Error updating category:",
        error.response?.data || error.message || error
      );
      toast.error("Erreur lors de la mise à jour de la catégorie");
    }
    closEditingeModal();
  };

  const closeChild = () => {
    setDisplayItem(false);
  };

  console.log("Rendering component. Current state:", {
    categories,
    editingCategory,
  });

  return (
    <section className="dashboard">
      <GestionHeader />
      <div className="sect3">
        <div>
          <Button
            type="button"
            onClick={openModal}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            aria-label="Create new category"
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              background: "#4CAF50",
              color: "white",
              width: "50%",
            }}
          >
            <FaPlus />
            Créer une nouvelle catégorie
          </Button>
          <div className="sect_table">
            <table>
              <thead>
                <tr>
                  <th>Index</th>
                  <th>Nom de la catégorie</th>
                  <th>Nombre de paniers</th>
                  <th>Nombre de produits</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <ScaleLoader
                    style={{
                      position: "absolute",
                      top: "70vh",
                      left: "25%",
                    }}
                    color="#4EAC04"
                  />
                )}

                {categories.map((category, index) => (
                  <tr key={category._id}>
                    <td>
                      <Tooltip placement="right" title="Détails">
                        <i
                          onClick={() => handleEditClick(category)}
                          className="fa-solid fa-eye"
                          style={{ color: "#4EAC04" }}
                        >
                          {"  "} {index + 1}
                        </i>
                      </Tooltip>
                    </td>
                    <td> {category.name} </td>
                    <td>{category.buckets.length} </td>
                    <td>
                      {category.buckets.reduce(
                        (total, bucket) => total + bucket.products.length,
                        0
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div className="sect_table">
            <LoginBonusAdmin />
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <CreationProduitForm
          handleCreateCategory={handleCreateCategory}
          handleUpdateCategory={handleUpdateCategory}
          editingCategory={null}
          setEditingCategory={setEditingCategory}
        />
      </Modal>

      <Modal isOpen={isEditModalOpen} onClose={closEditingeModal}>
        <EditingCategorie
          handleCreateCategory={handleCreateCategory}
          handleUpdateCategory={handleUpdateCategory}
          editingCategory={editingCategory}
          setEditingCategory={setEditingCategory}
          deletecategory={handleDeleteCategory}
        />
      </Modal>
      <ToastContainer />
    </section>
  );
};

export default Gestionnairepanier;
