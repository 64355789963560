import React, { useState, useEffect } from "react";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import Tooltip from "@mui/material/Tooltip";
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
interface Visionnage {
  duréeVideo: string;
  _id: string;
  dureeVideo: string;
  pointsDonnes: number;
  partenaire?: { nomPartenaire: string };
  idVideo: string;
  idUsager: string;
  GigaDonne: string;
  OperateurDonneur: string;
  idPartenaire: string;
  dateVisionnage: string;
}

interface HommeSandwich {
  idUsager: string;
  telUsager: string;
  _id: string;
  email: string;
  dateCreation: string;
}

const Details = (props: {
  idVis: string;
  idVidVis: string;
  idUsagerVis: string;
  gigaVis: string;
  opeVis: string;
  idPartVis: string;
  dureeVidVis: string;
  dateVis: string;
  onClose: () => void;
}) => {
  return (
    <div className="sect3_elem_details">
      <div>
        <div onClick={props.onClose} className="elem_details_closure">
          {" "}
          <i className="fa-solid fa-xmark"></i>{" "}
        </div>

        <div className="part_details_div">
          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails Visionnage </b>{" "}
              </span>
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> ID : &nbsp; </span> <span> {props.idVis} </span>{" "}
              </div>
              <div>
                <span> Durée Vidéo : &nbsp; </span>{" "}
                <span>
                  {" "}
                  {props.dureeVidVis} &nbsp; <b>S</b>{" "}
                </span>
              </div>
              <div>
                <span> Giga crédité : &nbsp; </span>{" "}
                <span>
                  {" "}
                  {props.gigaVis} {"MB "}{" "}
                </span>{" "}
              </div>
              <div>
                <span> Opérateur : &nbsp; </span> <span> {props.opeVis} </span>{" "}
              </div>
              <div>
                <span> ID_partenaire : &nbsp; </span>{" "}
                <span> {props.idPartVis} </span>{" "}
              </div>
              <div>
                <span> ID_usager : &nbsp; </span>{" "}
                <span> {props.idUsagerVis} </span>{" "}
              </div>
            </div>
          </div>

          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails Visionnage </b>{" "}
              </span>
            </div>

            <div className="row1_details_details">
              <br />
              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> ID_vidéo : &nbsp; </span> <span> {props.idVidVis} </span>{" "}
              </div>
              <div>
                <span> Date Visionnage : &nbsp; </span>{" "}
                <span>
                  {" "}
                  {new Date(props.dateVis).toLocaleString("fr-CI", {
                    day: "numeric",
                    month: "numeric",
                    year: "2-digit",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    timeZone: "Africa/Abidjan",
                  })}{" "}
                </span>{" "}
              </div>
              <div className="row_status_div"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Details2 = (props: {
  idHs: string;
  emailHs: string;
  idUsagerHs: string;
  telHs: string;
  dateDemandeHs: string;
  onClose: () => void;
}) => {
  return (
    <div className="sect3_elem_details">
      <div>
        <div onClick={props.onClose} className="elem_details_closure">
          {" "}
          <i className="fa-solid fa-xmark"></i>{" "}
        </div>

        <div className="part_details_div">
          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails Demande Homme Sandwich </b>{" "}
              </span>
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> ID : &nbsp; </span> <span> {props.idHs} </span>{" "}
              </div>
              <div>
                <span> Email : &nbsp; </span> <span> {props.emailHs} </span>
              </div>
              <div>
                <span> ID_usager : &nbsp; </span>{" "}
                <span> {props.idUsagerHs} </span>{" "}
              </div>
              <div>
                <span> Tel_usager : &nbsp; </span> <span> {props.telHs} </span>{" "}
              </div>
              <div>
                <span> Date demande : &nbsp; </span>{" "}
                <span>
                  {" "}
                  {new Date(props.dateDemandeHs).toLocaleDateString("fr-CI", {
                    day: "numeric",
                    month: "numeric",
                    year: "2-digit",
                    timeZone: "Africa/Abidjan",
                  })}{" "}
                </span>{" "}
              </div>
              <div></div>
            </div>
          </div>

          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails Demande Homme Sandwich </b>{" "}
              </span>
            </div>

            <div className="row1_details_details">
              <br />
              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div></div>
              <div></div>

              <div className="row_status_div"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Sect3 = () => {
  const [displayItem, setDisplayItem] = useState(false);
  const [displayItem2, setDisplayItem2] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currHommeSandwich, setCurrHommeSandwich] = useState<HommeSandwich>(
    {} as HommeSandwich
  );
  const [currVisionnage, setCurrVisionnage] = useState<Visionnage>(
    {} as Visionnage
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [itemsPerPage2, setItemsPerPage2] = useState(6);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [currentPage2, setCurrentPage2] = useState(1);
  const [totalPages2, setTotalPages2] = useState(0);

  const [value, setValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const closeChild = () => {
    setDisplayItem(false);
  };

  const closeChild2 = () => {
    setDisplayItem2(false);
  };

  const [visionnages, setVisionnages] = useState([]);
  const [hommesSandwich, setHommesSandwich] = useState([]);

  useEffect(() => {
    setLoading(true);
    const limit1 = itemsPerPage2;
    const page = currentPage2;
    Promise.all([
      axios.get(`${apiUrl}/api/visionnages?limit=${limit1}`),
      axios.get(`${apiUrl}/api/AddPartenaire?limit=${limit1}&page=${page}`),
    ])
      .then(([dataVisionnage, dataHommeSandwich]) => {
        console.log("datavisionnage", dataVisionnage.data);
        console.log("dataHommeSandwich", dataHommeSandwich.data);
        setVisionnages(dataVisionnage.data.viewings);
        setHommesSandwich(dataHommeSandwich.data.allAddPartenaire);
        setTotalPages2(dataHommeSandwich.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleVisionnage = (visionnage: Visionnage) => {
    setCurrVisionnage(visionnage);
    setDisplayItem(true);
  };

  const handleHommeSandwich = (hommeSandwich: HommeSandwich) => {
    setCurrHommeSandwich(hommeSandwich);
    setDisplayItem2(true);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = visionnages
    .filter(
      (visionnage: any) =>
        visionnage.idVideo?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        visionnage.OperateurDonneur?.toLowerCase().includes(
          searchTerm.toLowerCase()
        ) ||
        visionnage.usager.nom
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        visionnage.partenaire.nomPartenaire
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    )
    .slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber: React.SetStateAction<number>) =>
    setCurrentPage(pageNumber);
  const indexOfLastItem2 = currentPage2 * itemsPerPage2;
  const indexOfFirstItem2 = indexOfLastItem2 - itemsPerPage2;
  let currentItems2 = hommesSandwich
    .filter((hs: any) =>
      hs.email?.toLowerCase().includes(searchTerm2.toLowerCase())
    )
    .slice(indexOfFirstItem2, indexOfLastItem2);
  const paginate2 = (pageNumber: number) => {
    setCurrentPage2(pageNumber);
    setLoading(true);
    const limit1 = itemsPerPage2;
    const page = pageNumber;
    console.log("page:", pageNumber);
    Promise.all([
      axios.get(`${apiUrl}/api/AddPartenaire?limit=${limit1}&page=${page}`),
    ])
      .then(([dataHommeSandwich]) => {
        setHommesSandwich(dataHommeSandwich.data.allAddPartenaire);
        setTotalPages2(dataHommeSandwich.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    currentItems2 = hommesSandwich;
    console.log("currtItems: " + currentItems2);
  };

  const searchHandler2 = (e: any) => {
    setLoading(true);
    const limit1 = itemsPerPage2;
    const page = currentPage2;

    console.log("searchTerm2: " + searchTerm2);
    Promise.all([
      axios.get(
        `${apiUrl}/api/AddPartenaire?limit=${limit1}&page=${page}&search=${searchTerm2}`
      ),
    ])

      .then(([dataHommeSandwich]) => {
        setHommesSandwich(dataHommeSandwich.data.allAddPartenaire);
        console.log("searched: " + dataHommeSandwich.data.allAddPartenaire);
        setTotalPages2(dataHommeSandwich.data.totalPages);
        setLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="sect3">
      <div>
        <div className="sect3_top">
          <div>Visionnages récents</div>
        </div>

        <div className="sect_table">
          <table>
            <thead>
              <tr>
                <th>##</th>
                <th>Duree Video</th>
                <th>Points offerts</th>
                <th>Nom Partenaire</th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <ScaleLoader
                  style={{
                    position: "absolute",
                    top: "70vh",
                    left: "25%",
                  }}
                  color="#4EAC04"
                />
              )}
              {currentItems.map((visionnage: Visionnage, index) => (
                <tr key={visionnage._id}>
                  <td>{currentItems.length - index}</td>

                  <td>{visionnage.dureeVideo} </td>
                  <td>{visionnage.pointsDonnes}</td>

                  <td>{visionnage.partenaire?.nomPartenaire ?? "NULL"}</td>
                  <td>
                    <Tooltip placement="right" title="Détails">
                      <i
                        onClick={() => handleVisionnage(visionnage)}
                        className="fa-solid fa-tv"
                      ></i>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div>
        <div className="sect3_top2">
          <div className="hs_top_titre">
            <span>Demandes Partenariats</span>
          </div>
          <div className="search-bar-container">
            <input
              type="text"
              placeholder="Rechercher par email..."
              className="search-bar-hs"
              value={searchTerm2}
              onChange={(e) => setSearchTerm2(e.target.value)}
            />
            <button
              onClick={searchHandler2}
              disabled={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? true
                  : false
              }
              className={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? "search-button-disabled"
                  : "search-button"
              }
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>

        <div className="sect_table">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Email</th>
                <th>Date de demande</th>
                <th>Nom demandeur</th>
                <th>Numéro demandeur</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <ScaleLoader
                  style={{
                    position: "absolute",
                    top: "70vh",
                    left: "72%",
                  }}
                  color="#4EAC04"
                />
              )}

              {hommesSandwich.map((hs: HommeSandwich, index) => (
                <tr key={hs._id}>
                  <td>{hommesSandwich.length - index}</td>
                  <td>{hommesSandwich.length - index}</td>
                  <td>{hommesSandwich.length - index}</td>
                  <td>{hs.email}</td>
                  <td>
                    {new Date(hs.dateCreation).toLocaleString("fr-CI", {
                      day: "numeric",
                      month: "numeric",
                      year: "2-digit",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      timeZone: "Africa/Abidjan",
                    })}
                  </td>
                  <td>
                    <Tooltip placement="right" title="Détails">
                      <i
                        onClick={() => handleHommeSandwich(hs)}
                        className="fa-solid fa-tv"
                      ></i>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pagination-hs">
            <button
              className="pagination-button"
              onClick={() => paginate2(currentPage2 - 1)}
              disabled={currentPage2 === 1}
            >
              <i className="fa fa-angle-left"></i>
            </button>
            <span className="current-page">
              {currentPage2}/{totalPages2}
            </span>
            <button
              className="pagination-button"
              onClick={() => paginate2(currentPage2 + 1)}
              disabled={currentPage2 === totalPages2}
            >
              <i className="fa fa-angle-right"></i>
            </button>
          </div>
        </div>
      </div>

      {displayItem && (
        <Details
          idVis={currVisionnage._id}
          idVidVis={currVisionnage.idVideo}
          idUsagerVis={currVisionnage.idUsager}
          gigaVis={currVisionnage.GigaDonne}
          opeVis={currVisionnage.OperateurDonneur}
          idPartVis={currVisionnage.idPartenaire}
          dureeVidVis={currVisionnage.duréeVideo}
          dateVis={currVisionnage.dateVisionnage}
          onClose={closeChild}
        />
      )}

      {displayItem2 && (
        <Details2
          idHs={currHommeSandwich._id}
          emailHs={currHommeSandwich.email}
          idUsagerHs={currHommeSandwich.idUsager}
          telHs={currHommeSandwich.telUsager}
          dateDemandeHs={currHommeSandwich.dateCreation}
          onClose={closeChild2}
        />
      )}
    </div>
  );
};

export default Sect3;
