import React, { useState, useEffect } from "react";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

interface Partner {
  _id: string;
  nomPartenaire: string;
  logo?: {
    data?: {
      data?: number[];
    };
  };
}

interface Video {
  partenaire?: {
    id: string;
  };
  playCount?: number;
}

interface PartnerWithPlayCount extends Partner {
  totalPlayCount: number;
}

interface PartnerProps {
  partner: React.ReactNode;
  visionnages: React.ReactNode;
  logo: string | undefined;
}

const Partner: React.FC<PartnerProps> = ({ partner, visionnages, logo }) => {
  return (
    <div className="sect2_partner">
      <div>
        <b>{partner}</b>
        <b>
          {visionnages} <i className="fa-solid fa-eye"></i>{" "}
        </b>
      </div>
      <div>
        <img className="partner_logo" src={logo} alt="" />
      </div>
    </div>
  );
};

type SortOrder = "none" | "asc" | "desc";

const Sect2: React.FC = () => {
  const [partners, setPartners] = useState<PartnerWithPlayCount[]>([]);
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState<SortOrder>("none");
  const limit = 6;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [partnersResponse, videosResponse] = await Promise.all([
          axios.get(`${apiUrl}/api/partenaires?limit=${limit}&page=1&search=`),
          axios.get(`${apiUrl}/api/video/getVideos`),
        ]);

        const partnersData = partnersResponse.data.partenaires;
        const videos: Video[] = videosResponse.data.videos || [];

        const partnersWithPlayCounts = partnersData.map((partner: Partner) => {
          const partnerVideos = videos.filter(
            (video) => video.partenaire?.id === partner._id
          );
          const totalPlayCount = partnerVideos.reduce(
            (sum, video) => sum + (video.playCount || 0),
            0
          );
          return { ...partner, totalPlayCount };
        });

        setPartners(partnersWithPlayCounts);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getSortedPartners = () => {
    if (sortOrder === "none") return partners;

    return [...partners].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.totalPlayCount - b.totalPlayCount;
      } else {
        return b.totalPlayCount - a.totalPlayCount;
      }
    });
  };

  const handleSort = () => {
    const orders: SortOrder[] = ["none", "desc", "asc"];
    const currentIndex = orders.indexOf(sortOrder);
    const nextIndex = (currentIndex + 1) % orders.length;
    setSortOrder(orders[nextIndex]);
  };

  const getSortIcon = () => {
    switch (sortOrder) {
      case "asc":
        return <FaSortUp className="inline-block ml-2" />;
      case "desc":
        return <FaSortDown className="inline-block ml-2" />;
      default:
        return <FaSort className="inline-block ml-2" />;
    }
  };

  return (
    <div className="sect2">
      <div className="mb-4 flex justify-between items-center">
        <button
          onClick={handleSort}
          className="flex items-center px-4 py-2 bg-gray-100 rounded hover:bg-gray-200"
        >
          Trier par vues {getSortIcon()}
        </button>
      </div>

      {loading ? (
        <ScaleLoader
          style={{
            position: "absolute",
            top: "7vh",
            left: "27%",
          }}
          color="#4EAC04"
        />
      ) : (
        <>
          {getSortedPartners()
            .slice(0, 6)
            .map((partenaire, index) => {
              const img = partenaire?.logo?.data?.data;
              const base64String = img
                ? btoa(
                    String.fromCharCode.apply(
                      null,
                      Array.from(new Uint8Array(img))
                    )
                  )
                : "";

              return (
                <Partner
                  key={partenaire._id || index}
                  partner={
                    partenaire.nomPartenaire.length > 7
                      ? partenaire.nomPartenaire.substring(0, 7) + "..."
                      : partenaire.nomPartenaire
                  }
                  visionnages={partenaire.totalPlayCount.toLocaleString()}
                  logo={`data:image/png;base64,${base64String}`}
                />
              );
            })}
          <a href="/partenaires" className="btn_see_more">
            Voir plus
          </a>
        </>
      )}
    </div>
  );
};

export default Sect2;
